
import {useContext, useEffect, useState} from 'react';

import { useNavigate  } from "react-router-dom";

import {AppContext} from '../../global/App/AppState';
import { UserContext } from '../../global/User/UserState';

const HomeViewModel = () => {
    const navigate = useNavigate();

    const { appProvider } = useContext(AppContext);
    const { userProvider } = useContext(UserContext);
    
    const [sections, setSections] = useState([]);

    const [dependancies] = useState({
        setSections: setSections,
        appProvider: appProvider,
        userProvider: userProvider
    });

    useEffect(() => {
        if (!dependancies.userProvider || !dependancies.userProvider.roles || dependancies.userProvider.roles.length === 0)  {
            navigate(`/`);
            return;
        }

        let authorizedSections = [];

        dependancies.appProvider.sections.forEach(section => {
            if(section.roles.some(r=> dependancies.userProvider.roles.includes(r)))
                authorizedSections.push(section);
        });
      
        dependancies.setSections(authorizedSections);

        return () => {
        };
        
    }, [dependancies,navigate]);

    function onNavigate(page) {
        navigate(`/${page}`);
    }

    return {
        viewModel: {
            onNavigate: onNavigate,
            sections: sections
        }
    };
}

export default HomeViewModel;