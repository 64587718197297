import {useContext, useState, useEffect} from 'react';

import { useNavigate  } from "react-router-dom";

import {WebServiceContext} from '../../global/WebService/WebServiceState';
import {StorageContext} from '../../global/Storage/StorageState';
import { selectCheckpoints, selectDates, selectTimes, toAppointmentList } from '../../utils/checkpoints';
import { download } from '../../utils/common';

const CheckpointsViewModel = () => {
    const navigate = useNavigate();

    const { professionalWebService, error } = useContext(WebServiceContext);
    const { storageProvider } = useContext(StorageContext);

    const [filterItems, setFilterItems] = useState([]);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    const [dependancies] = useState({
        isBusy: isBusy,
        professionalWebService: professionalWebService,
        storageProvider: storageProvider
    });

    useEffect(() => {
        const fetchData = async function (professionalWebService, storageProvider) {
            storageProvider.clearCheckpoints();
            let data = await professionalWebService.getCheckpoints();
            storageProvider.setCheckpoints(data);            
        }

        if(!dependancies.isBusy) {
            setIsBusy(true);
            if(!dependancies.storageProvider.checkpoints || dependancies.storageProvider.checkpoints.length === 0) 
                fetchData(dependancies.professionalWebService, dependancies.storageProvider);
            setIsBusy(false);
        }

        return () => {
        };
        
    }, [dependancies]);

    async function onClick(item) {
        navigate(`/checkpoint/${item.globalId}`);
    }

    async function onDownload() {
        let checkpointGlobalId = (storageProvider.checkpointsFilter.checkpoint) 
            ? storageProvider.checkpointsFilter.checkpoint.globalId
            : "";
        let date = (storageProvider.checkpointsFilter.date)  
            ? `${storageProvider.checkpointsFilter.date}T00:00:00`
            : "";
        let time = (storageProvider.checkpointsFilter.time)  
            ? `1970-01-01T${storageProvider.checkpointsFilter.time}`
            : ""
        setIsBusy(true);
        let data = await professionalWebService.getCheckpointsAsExcel(checkpointGlobalId, date, time);
        download(data, `checkpoint-export.xlsx`);
        setIsBusy(false);
    }

    async function onRefresh() {
        storageProvider.clearCheckpoints();
        let data = await professionalWebService.getCheckpoints();
        storageProvider.setCheckpoints(data);   
    }

    function onShowFilter(type) {
        switch (type) {
            case 1:
                setFilterItems(selectCheckpoints(storageProvider.checkpoints, 1));
                break;
            case 2:
                setFilterItems(selectDates(storageProvider.checkpointsFilter.checkpoint, 2));
                break;
            case 3:
                setFilterItems(selectTimes(storageProvider.checkpointsFilter.checkpoint, storageProvider.checkpointsFilter.date, 3));
                break;
            default:
                setFilterItems([]);
                return;
        }
        setPopUpVisible(true);
    }

    function onFilter(key, type) {
        switch (type) {
            case 1:
                storageProvider.setCheckpointsFilter({
                    checkpoint: storageProvider.checkpoints.filter(c => 
                        {   
                            return c.globalId === key;
                        })[0],
                    date: null,
                    time: null
                });
                break;
            case 2:
                storageProvider.setCheckpointsFilter({
                    checkpoint: storageProvider.checkpointsFilter.checkpoint,
                    date: key,
                    time: null
                });
                break;
            case 3:
                storageProvider.setCheckpointsFilter({
                    checkpoint: storageProvider.checkpointsFilter.checkpoint,
                    date: storageProvider.checkpointsFilter.date,
                    time: key
                });
                break;
            default:
                break;
        }
        setFilterItems([]);
        setPopUpVisible(false);
    }

    return {
        viewModel: {
            appointments: toAppointmentList(storageProvider.checkpoints, storageProvider.checkpointsFilter),
            onClick: onClick,
            onDownload: onDownload,
            onRefresh: onRefresh,
            filter: storageProvider.checkpointsFilter,
            onShowFilter: onShowFilter,
            popUpVisible: popUpVisible, 
            setPopUpVisible: setPopUpVisible,
            filterItems: filterItems,
            onFilter: onFilter,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    }
};

export default CheckpointsViewModel;
