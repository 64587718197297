import React from "react";
import styled from "styled-components";
import FontAwesome from "react-fontawesome";
import Text from "../text/Text";

const StyledPrevious = styled.div`
  margin: 24px 0px;
  display: flex;
  color: var(--color-button-primary);
  cursor: pointer;
`;

const StyledContent = styled.div`
  margin: 5px;
`;

export const PreviuosPage = ({ onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <StyledPrevious onClick={handleOnClick}>
      <StyledContent>
        <FontAwesome name="arrow-left" />
      </StyledContent>
      <StyledContent>
        <Text value={"BACK"} />
      </StyledContent>
    </StyledPrevious>
  );
};
