import Moment from "moment";

import Button from "../../components/buttons/Button";
import Error from "../../components/containers/Error";
import FlexBox from "../../components/containers/FlexBox";
import InfoRowBox from "../../components/containers/InfoRowBox";
import Form from "../../components/containers/Form";
import Introduction from "../../components/containers/Introduction";
import MarginBox from "../../components/containers/MarginBox";
import Page from "../../components/containers/Page";
import Text from "../../components/text/Text";
import Title from "../../components/text/Title";

import CheckpointViewModel from "./Checkpoint.VM";
import Input from "../../components/inputs/Input";
import { Secondary } from "../../core/enums/ButtonType";
import ScannerInput from "../../components/inputs/Scanner";
import { PreviuosPage } from "../../components/buttons/Back";
import Success from "../../components/containers/Success";

const Checkpoint = () => {
  const { viewModel } = CheckpointViewModel();

  return (
    <Page>
      <PreviuosPage onClick={() => viewModel.navigate(-1)} />

      {viewModel.appointment ? (
        <FlexBox gap={"0px"}>
          <Introduction>
            <Title
              onDoubleClick={() => {
                navigator.clipboard.writeText(viewModel.appointment.nipedNumber.toString());
              }}
              cursor="pointer"
            >
              <Text text={viewModel.appointment.nipedNumber.toString()} />
            </Title>
            <InfoRowBox>
              <Text value="CHECKPOINT_FIELD_DATETIME" />
              <Text text={Moment(viewModel.appointment.date).format("DD-MM-YYYY HH:mm")} />
            </InfoRowBox>
            <InfoRowBox>
              <Text value="CHECKPOINT_FIELD_LOCATION" />
              <Text text={viewModel.appointment.nameLocation + " (" + viewModel.appointment.department + ")"} />
            </InfoRowBox>
            <InfoRowBox>
              <Text value="CHECKPOINT_FIELD_NAME" />
              <Text text={(viewModel.appointment.name.firstname || "...").concat(" ", viewModel.appointment.name.lastname || "...")} />
            </InfoRowBox>
            <InfoRowBox>
              <Text value="CHECKPOINT_FIELD_GENDER" />
              <Text text={viewModel.appointment.sex || "..."} />
            </InfoRowBox>
            {viewModel.appointment.barcodes && viewModel.appointment.barcodes.length > 0 && (
              <InfoRowBox>
                <Text value="ASSIGNED_BARCODES" />
                <span>
                  {viewModel.appointment.barcodes.map((barcode) => (
                    <p> {barcode}</p>
                  ))}
                </span>
              </InfoRowBox>
            )}
            <InfoRowBox $lineHeight="40px">
              <Text value="CHECKPOINT_FIELD_DOB" />

              <Input
                type="date"
                border="1px solid #486c98"
                background="white"
                className="dob"
                name="dob"
                height="40px"
                min="1900-01-01"
                max="2024-12-31"
                value={viewModel.dob}
                onChange={(e) => {
                  if (!viewModel.isBusy) viewModel.setDob(e.target.value);
                }}
              />
            </InfoRowBox>
            {viewModel.message !== "BARCODE_SAVED_SUCCESS" &&
              viewModel.message !== "BARCODE_ALREADY_SAVED" &&
              viewModel.message !== "ERROR_SAVING_BARCODE" &&
              viewModel.message !== "ERROR_WITH_SHIPMENT_STATE" &&
              viewModel.message !== "ERROR_SHIPMENT_NOT_FOUND" &&
              (viewModel.message && !viewModel.isValid ? <Error value={viewModel.message} /> : <Success value={viewModel.message} />)}
            {viewModel.isValid && (
              <InfoRowBox>
                <Text value="CHECKPOINT_BARCODE" />
                {<ScannerInput scannedValue={viewModel.scannedValue} setScannedValue={viewModel.setScannedValue} />}
              </InfoRowBox>
            )}
            {viewModel.message === "BARCODE_SAVED_SUCCESS" ? (
              <Success value={viewModel.message} />
            ) : (
              <Button
                styleType={Secondary}
                onClick={(e) => {
                  if (e.key === "Enter") return;

                  e.preventDefault();
                  !viewModel.isValid ? viewModel.validateDob() : viewModel.assignBarcode();
                }}
              >
                {viewModel.isValid ? <Text text={"ASSIGN_BARCODE"} /> : <Text value="VALIDATE_DOB" />}
              </Button>
            )}
            {viewModel.message === "BARCODE_ALREADY_SAVED" && <Error value={viewModel.message} />}
            {(viewModel.message === "ERROR_SAVING_BARCODE" ||
              viewModel.message === "BARCODE_ALREADY_SAVED" ||
              viewModel.message === "ERROR_SHIPMENT_NOT_FOUND" ||
              viewModel.message === "ERROR_WITH_SHIPMENT_STATE") && <Error value={viewModel.message} />}
          </Introduction>
          <Form>
            <h3>
              <Text value="CHECKPOINT_TITLE" />
            </h3>

            <MarginBox bottom={"20px"}>
              <Text value="CHECKPOINT_TEXT_CANCEL" />
            </MarginBox>
            <Button
              onClick={(e) => {
                e.preventDefault();
                viewModel.onCancel();
              }}
            >
              <Text value="CHECKPOINT_BUTTON_CANCEL" />
            </Button>
            <MarginBox top={"32px"} bottom={"20px"}>
              <Text value="CHECKPOINT_TEXT_CHANGE" />
            </MarginBox>
            <Button
              onClick={(e) => {
                e.preventDefault();
                viewModel.onChange();
              }}
            >
              <Text value="CHECKPOINT_BUTTON_CHANGE" />
            </Button>
          </Form>
        </FlexBox>
      ) : (
        ""
      )}
      <Error value={viewModel.pageError} />
      <Error value={viewModel.error} />
    </Page>
  );
};

export default Checkpoint;