import { useContext } from 'react';

import { Navigate } from 'react-router-dom';

import {UserContext} from '../../global/User/UserState';

const ProtectedRoute = ({roles, children}) => {

    const { userProvider } = useContext(UserContext);

    if (!userProvider || !userProvider.roles || userProvider.roles.length === 0) 
      return <Navigate to="/" replace />;
    
    if(!roles || roles.length === 0)
        return <Navigate to="/" replace />;

    let authorized = false;
    let userRoles = Array.isArray(userProvider.roles) ? userProvider.roles : [userProvider.roles];
    roles.forEach((role) => {
      if (userRoles.some((r) => r === role)) authorized = true;
    });
    
    return authorized ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;