import React from "react";
import Error from "../../components/containers/Error";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import RegisterViewModel from "./RegisterUser.VM";
import { RegisterUserForm } from "./RegisterUser.Components";
import Text from "../../components/text/Text";
import Button from "../../components/buttons/Button";
import Password from "../../components/inputs/password";
import Introduction from "../../components/containers/Introduction";
import Title from "../../components/text/Title";
import Input from "../../components/inputs/Input";

const RegisterUser = () => {
  const { viewModel } = RegisterViewModel();

  return (
    <Page>
      <FlexBox gap={"0px"}>
        {" "}
        <Introduction>
          <Title>
            <Text value="REGISTER_TITLE" />
          </Title>
          <Text value="REGISTER_TEXT" />
        </Introduction>
        <RegisterUserForm
          onSubmit={async (event) => {
            event.preventDefault();
            viewModel.sendPasswords();
          }}
        >
          <h1>
            <Text value="MAKE_ACCOUNT_TITLE" />
          </h1>
          <Input value={viewModel.username} type="email" readOnly />
          <Password
            errorMessage={viewModel.error}
            password={viewModel.state.password}
            passwordCheck={viewModel.state.passwordCheck}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setState({ ...viewModel.state, [e.target.name]: e.target.value });
              viewModel.onEqual(false);
            }}
            onValidationChange={(e) => viewModel.onValidationChange(e)}
            valid={viewModel.valid}
            notEqual={viewModel.notEqual}
          />
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <Button width="45%">
            <Text value="NEXT" />
          </Button>
        </RegisterUserForm>
      </FlexBox>
    </Page>
  );
};

export default RegisterUser;
