import React from 'react';

import Moment from 'moment';

import Button from '../../components/buttons/Button'
import Text from '../../components/text/Text';
import Title from '../../components/text/Title';
import Error from '../../components/containers/Error';
import Page from '../../components/containers/Page';
import Form from '../../components/containers/Form';
import FlexBox from '../../components/containers/FlexBox';
import Grid from '../../components/containers/Grid';
import MarginBox from '../../components/containers/MarginBox';
import Input from '../../components/inputs/Input';
import Introduction from '../../components/containers/Introduction';

import {Item, ItemHeader, ItemRow, ReleaseButton} from './Reports.Components';

import ReportsViewModel from './Reports.VM';

const Reports = () => {
  const {
    viewModel
  } = ReportsViewModel();

  return (    
    <Page>
      <FlexBox gap={"0px"}>
        <Introduction>
          <Title>
            <Text value="REPORTS_INTRODUCTION_TITLE" />
          </Title>
          <Text value="REPORTS_INTRODUCTION_TEXT" />
        </Introduction>
        <Form onSubmit={async (event) => {
            event.preventDefault();
            viewModel.onSearch();
          }}>
          <h3>
            <Text value="REPORTS_TITLE" />
          </h3>
          <MarginBox bottom={"20px"}>
            <Text value="REPORTS_TEXT" />
          </MarginBox>
          <Input placeholder="REPORTS_PLACEHOLDER_SEARCH"
                  value={viewModel.search}
                  onChange={(e) => {
                    if(!viewModel.isBusy)
                      viewModel.setSearch(e.target.value);
                  }}/>
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <Button>
            <Text value="REPORTS_BUTTON_SUBMIT"/>
          </Button>
        </Form>
      </FlexBox>
      <Grid>
        {
          viewModel.reports
              .map(d => (
                <Item key={d.globalId}>
                  <div onClick={() => {
                      viewModel.onClick(d);
                  }}>
                    <ItemHeader>
                      <Title>{d.nipedNumber}</Title>
                      <div>{Moment(d.dateOfBirth).format('DD-MM-YYYY')} ({d.sex})</div>
                    </ItemHeader>
                    <ItemRow>
                      <Text value="REPORTS_FIELDS_ZIPCODE" />
                      {d.zipcode || "..."}
                    </ItemRow>
                    <ItemRow isEven>
                      <Text value="REPORTS_FIELDS_HOUSENUMBER" />
                      {d.housenumber || "..."}
                    </ItemRow>
                    <ItemRow >
                      <Text value="REPORTS_FIELDS_READY" />
                      { d.ready ? Moment(d.ready).format('DD-MM-YYYY HH:mm') : "..." }
                    </ItemRow>
                    <ItemRow isEven>
                      <Text value="REPORTS_FIELDS_LAST_UNDER_REVIEW" />
                      { d.underReview ? Moment(d.underReview).format('DD-MM-YYYY HH:mm') : "..." }
                    </ItemRow>
                    <ItemRow >
                      <Text value="REPORTS_FIELDS_NOTIFIED" />
                      { d.notified ? Moment(d.notified).format('DD-MM-YYYY HH:mm') : "..." }
                    </ItemRow>
                  </div>
                  <ReleaseButton disabled={!(d.underReview && viewModel.allowRelease())} onClick={() => {
                      viewModel.onRelease(d);
                    }}>
                    <Text value="REPORTS_BUTTON_RELEASE"/>
                  </ReleaseButton>
                </Item>
              ))
        }
      </Grid>
    </Page>
  );
}

export default Reports;