import './index.css';

import React from 'react';

import { createRoot } from 'react-dom/client';

import { ConfigurationProvider } from './global/Configuration/ConfigurationState'
import { LocalizationProvider } from './global/Localization/LocalizationState'
import { WebServiceProvider } from './global/WebService/WebServiceState'
import { AppProvider } from './global/App/AppState';
import { UserProvider } from './global/User/UserState';
import { ModalProvider } from './global/Modal/ModalState';
import { StorageProvider } from './global/Storage/StorageState';

import App from './pages/App';

createRoot(document.getElementById('root')).render(
  // [20220621 MK] There is a bug in <React.StrictMode> which causes each page to load twice. See: https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
  //<React.StrictMode>
    <AppProvider>
      <ConfigurationProvider>
        <LocalizationProvider>
          <ModalProvider>
            <UserProvider>
              <WebServiceProvider>
                <StorageProvider>
                  <App />
                </StorageProvider>
              </WebServiceProvider>
            </UserProvider>
          </ModalProvider>
        </LocalizationProvider>
      </ConfigurationProvider>
    </AppProvider>
  //</React.StrictMode>
);