import {useContext, useState, useEffect} from 'react';

import { useNavigate, useParams  } from "react-router-dom";

import {WebServiceContext} from '../../global/WebService/WebServiceState';
import {StorageContext} from '../../global/Storage/StorageState';
import { download } from '../../utils/common';

const ReportViewModel = () => {
    const navigate = useNavigate();

    const { id } = useParams();

    const { professionalWebService, error } = useContext(WebServiceContext);
    const { storageProvider } = useContext(StorageContext);
    
    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [checkCVD, setCheckCVD] = useState(false);

    const [dependancies] = useState({
        isBusy: isBusy,
        id: id,
        professionalWebService: professionalWebService,
        storageProvider: storageProvider
    });

    useEffect(() => {
        const fetchData = async function (professionalWebService, storageProvider, id) {
            storageProvider.setReport({});
            let report = await professionalWebService.getReport(id);
            storageProvider.setReport(report);
            setCheckCVD(report.data.Gezondheidsfactoren.Categories.filter(x => x.PageID === 9999).length > 0);
        }

        if(!dependancies.isBusy) {
            setIsBusy(true);
            if(!dependancies.storageProvider.report || !dependancies.storageProvider.report.data || !(dependancies.storageProvider.report.data.globalId === dependancies.id)) 
                fetchData(dependancies.professionalWebService, dependancies.storageProvider, dependancies.id);
            setIsBusy(false);
        }

        return () => {
        };
        
    }, [dependancies]);

    function onCategoryClick(categoryId) {
        navigate(`/report-page/${categoryId}`);
    }

    async function onDownload() {
        setIsBusy(true);
        let data = await professionalWebService.getReportAsPDF(id);
        download(data, `${id}.pdf`);
        setIsBusy(false);
    }

    return {
        viewModel: {
            report: storageProvider.report,
            pageError: pageError,
            checkCVD: checkCVD,
            onCategoryClick: onCategoryClick,
            onDownload: onDownload,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default ReportViewModel;