
import Moment from "moment";

import { useContext, useEffect, useState } from 'react';

import { useParams, useNavigate  } from "react-router-dom";

import { LocalizationContext } from '../../global/Localization/LocalizationState';
import { WebServiceContext } from '../../global/WebService/WebServiceState';
import { ModalContext } from '../../global/Modal/ModalState';
import { StorageContext } from '../../global/Storage/StorageState';

import { getAppointment, toAppointmentList } from '../../utils/checkpoints';
import { selectDates, selectTimes } from '../../utils/checkpoints';

const CheckpointEditViewModel = () => {
    const navigate = useNavigate();

    const { id } = useParams();

    const { localizationProvider } = useContext(LocalizationContext);
    const { storageProvider } = useContext(StorageContext);
    const { professionalWebService, error } = useContext(WebServiceContext);
    const { modalProvider } = useContext(ModalContext);

    const [filter, setFilter] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [checkpoint, setCheckpoint] = useState({});
    const [appointment] = useState(getAppointment(storageProvider.checkpoints, id));
    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    const [dependancies] = useState({
        isBusy: isBusy,
        appointment: appointment,
        professionalWebService: professionalWebService
    });

    useEffect(() => {
        const fetchData = async function (appointment, professionalWebService) {
            setCheckpoint(await professionalWebService.getCheckpoint(appointment.checkpointGlobalId));
        }

        if(!dependancies.isBusy) {
            setIsBusy(true);
            fetchData(dependancies.appointment, dependancies.professionalWebService);
            setIsBusy(false);
        }

        return () => {
        };
        
    }, [dependancies]);

    function onShowFilter(type) {
        switch (type) {
            case 2:
                setFilterItems(selectDates(checkpoint, 2));
                break;
            case 3:
                setFilterItems(selectTimes(checkpoint, filter.date, 3));
                break;
            default:
                setFilterItems([]);
                return;
        }
        setPopUpVisible(true);
    }

    function onFilter(key, type) {
        switch (type) {
            case 2:
                setFilter({
                    date: key,
                    time: null
                });
                break;
            case 3:
                setFilter({
                    date: filter.date,
                    time: key
                });
                break;
            default:
                break;
        }
        setFilterItems([]);
        setPopUpVisible(false);
    }

    function onSubmit() {
        let text = localizationProvider.getLocalization("CHECKPOINT_EDIT_MODAL_SAVE_DESCRIPTION", "");

        let fullDate = Moment(filter.date + "T" + filter.time);

        text = text.replace('{date}',fullDate.format('DD-MM-YYYY'))
                   .replace('{time}',fullDate.format('HH:mm'));

        modalProvider.showModal("CHECKPOINT_EDIT_MODAL_SAVE_TITLE", text, null, onSave, modalProvider.hideModal); 
    }

    async function onSave() {
        modalProvider.hideModal();
        
        let appointments = toAppointmentList([checkpoint], {
            checkpoint: checkpoint,
            date: filter.date,
            time: filter.time
        });

        if(!appointments || appointments.length === 0) {
            modalProvider.showModal("CHECKPOINT_EDIT_MODEL_ERROR_TITLE", "CHECKPOINT_EDIT_MODAL_ERROR_DESCRIPTION", null, modalProvider.hideModal); 
            return;
        }
        
        setIsBusy(true);
        let data = {
            guid: appointment.appointmentGlobalId
        };
        let result = await professionalWebService.postCheckpoint(appointments[0].checkpointGlobalId, appointments[0].globalId, appointment.nipedNumber, data);
        setIsBusy(false);

        if(result) {
            modalProvider.showModal("CHECKPOINT_EDIT_MODAL_SAVED_TITLE", "CHECKPOINT_EDIT_MODAL_SAVED_DESCRIPTION", null, onPrevious, null, null, null, onPrevious); 
            storageProvider.clearCheckpoints();
        }
        else 
            modalProvider.showModal("CHECKPOINT_EDIT_MODEL_ERROR_TITLE", "CHECKPOINT_EDIT_MODAL_ERROR_DESCRIPTION", null,  modalProvider.hideModal); 
    }

    function onPrevious() {
        modalProvider.hideModal();
        navigate(-2);
    }

    return {
        viewModel: {
            appointment: appointment,
            onSubmit: onSubmit,
            onShowFilter: onShowFilter,
            popUpVisible: popUpVisible, 
            setPopUpVisible: setPopUpVisible,
            filter: filter,
            filterItems: filterItems,
            onFilter: onFilter,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default CheckpointEditViewModel;