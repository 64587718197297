import styled from "styled-components";
import Button from "../buttons/Button";

import Text from "../text/Text";

const BlockControl = (data) => {
    return (
        <BlockRow>
            <BlockCell width={data.table ? "50%" : "100%"} padding={data.table ? "0 5px 0 0" : null}>
                <div>
                    {data.elements ? data.elements.map((component, i) => {
                        if (component.type === 0)
                            return <Text key={i} text={component.value } />;
                        else return component.value;
                    }) : ''}
                </div>
            </BlockCell>
            {data.table && (
                <BlockCell width="50%">
                    <TipBlock>
                        <TipTitle>
                            <Text text={data.table.props.data.Fields[0].Field.Value} />
                        </TipTitle>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            window.open(getPdfReference(data.page_id))
                        }}>
                            <Text value={"REPORT_BUTTON_SHOW"} />
                        </Button>
                    </TipBlock>
                </BlockCell>
            )}
            {data.children}
        </BlockRow>
    );
}

export default BlockControl;

const getPdfReference = (id) => {
    const pdfReference = {
        115: "../references/Mijn_plan-Bewegen_v20.pdf",
        116: "../references/Mijn_plan-Roken_v20.pdf",
        117: "../references/Mijn_plan-Alcohol_v20.pdf",
        118: "../references/Mijn_plan-Voeding_v20.pdf",
        106: "../references/Mijn_plan-Gewicht_v20.pdf",
    };
    return pdfReference[id] || '';
};

const BlockRow = styled.div`
    margin: 15px 0;
`;

const BlockCell = styled.div`
    width: ${props => (props.width ? props.width : "100%")};
    ${
        props => {
            if(props.padding)
                return `padding: ${props.padding};`
        }  
    }
    display: table-cell;
    vertical-align: top;
     > div {
        white-space: pre-line;
        width: 100%;
     }
`;

const TipBlock = styled.div`
    background-color: #efefef;
    padding: 10px 10px 1px 10px;
`;

const TipTitle = styled.div`
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
`;