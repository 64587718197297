import React, { useContext, useState } from "react";

import useIdentityWebService from './Identity/useIdentityWebService';
import useProfessionalWebService from './Professional/useProfessionalWebService';

import { ConfigurationContext } from '../Configuration/ConfigurationState';
import { AppContext } from '../App/AppState';
import { LocalizationContext } from '../Localization/LocalizationState';

export const WebServiceContext = React.createContext();

export const WebServiceProvider = ({children}) => {
    
    const [error, setError] = useState(null);
    const [authorization, setAuthorization] = useState({});

    const { configurationProvider } = useContext(ConfigurationContext);
    const { appProvider } = useContext(AppContext);
    const { localizationProvider } = useContext(LocalizationContext);

    const { postLogin, resetPassword, getUsername } = useIdentityWebService(appProvider, configurationProvider.urls.identity, setAccessToken, createHeaders, setError);

    const {
      getReports,
      getReport,
      getReportAsPDF,
      releaseReport,
      getBiometrics,
      getBiometric,
      putBiometric,
      getLabs,
      getLab,
      putLab,
      getCheckpoints,
      getCheckpointsAsExcel,
      getCheckpoint,
      postCheckpoint,
      deleteCheckpoint,
      validateDob,
      postBarcode,
    } = useProfessionalWebService(appProvider, configurationProvider.urls.professional, createHeaders, setError);

    const provider = {
      identityWebService: {
        postLogin: postLogin,
        resetPassword: resetPassword,
        getUsername: getUsername,
      },
      professionalWebService: {
        getReports: getReports,
        getReport: getReport,
        getReportAsPDF: getReportAsPDF,
        releaseReport: releaseReport,
        getBiometrics: getBiometrics,
        getBiometric: getBiometric,
        putBiometric: putBiometric,
        getLabs: getLabs,
        getLab: getLab,
        putLab: putLab,
        getCheckpoints: getCheckpoints,
        getCheckpointsAsExcel: getCheckpointsAsExcel,
        getCheckpoint: getCheckpoint,
        postCheckpoint: postCheckpoint,
        deleteCheckpoint: deleteCheckpoint,
        validateDob: validateDob,
        postBarcode: postBarcode,
      },
      error,
      setError,
    };

    function setAccessToken(value) {
        setAuthorization({
            jwt:value
        });
    }

    function createHeaders() {
        return { 
            'Authorization': `Bearer ${authorization.jwt}`,
            'Accept-Language': localizationProvider.locale
        };
    }
    
    return (
        <WebServiceContext.Provider value={provider}>
            {children}
        </WebServiceContext.Provider>
    )
};