import React from 'react';

import Moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/buttons/Button";
import Error from "../../components/containers/Error";
import FlexBox from "../../components/containers/FlexBox";
import InfoRowBox from "../../components/containers/InfoRowBox";
import Form from "../../components/containers/Form";
import Introduction from "../../components/containers/Introduction";
import MarginBox from "../../components/containers/MarginBox";
import Page from "../../components/containers/Page";
import Text from "../../components/text/Text";
import Title from "../../components/text/Title";

import { PopUp, PopUpScreen } from "../checkpoints/Checkpoints.Components";
import { CloseButton } from "../../components/modals/Modal";

import CheckpointEditViewModel from "./CheckpointEdit.VM";

const CheckpointEdit = () => {
    const {
        viewModel
    } = CheckpointEditViewModel();

    return [
        <PopUpScreen key={"PopUpScreen"} visible={viewModel.popUpVisible}>
            <div>
                <PopUp>
                    <Title>
                        <Text value="CHECKPOINTS_POPUP_FILTER_TITLE" />
                    </Title>
                    <Text value="CHECKPOINTS_POPUP_FILTER_TEXT" />
                    {
                        viewModel.filterItems.map(f => (
                            <Button key={f.key} onClick={
                                (e) => {
                                    e.preventDefault();
                                    viewModel.onFilter(f.key, f.type);
                                }
                            }>
                                <Text text={f.value}/>
                            </Button>
                        ))
                    }

                    <MarginBox top="16px" onClick={
                        (e) => {
                            e.preventDefault();
                            viewModel.setPopUpVisible(false);
                        }
                    }>
                        <CloseButton>
                            <FontAwesomeIcon icon={faTimes} />
                        </CloseButton>
                    </MarginBox>
                </PopUp>
            </div>
        </PopUpScreen>,
        <Page key={"Page"}>
            {
                viewModel.appointment
                ? <FlexBox gap={"0px"}>
                    <Introduction>
                        <Title>
                            <Text text={viewModel.appointment.nipedNumber.toString()} />
                        </Title>
                        <InfoRowBox>
                            <Text value="CHECKPOINT_EDIT_FIELD_DATETIME" />
                            <Text text={Moment(viewModel.appointment.date).format('DD-MM-YYYY HH:mm')} />
                        </InfoRowBox>
                        <InfoRowBox>
                            <Text value="CHECKPOINT_EDIT_FIELD_LOCATION" />
                            <Text text={viewModel.appointment.name + ' (' + viewModel.appointment.department + ')'} />
                        </InfoRowBox>
                        <InfoRowBox>
                            <Text value="CHECKPOINT_EDIT_FIELD_ADRESS" />
                            <Text text={(viewModel.appointment.street || "...").concat(' ', (viewModel.appointment.houseNumber || "...")) + ' ' + (viewModel.appointment.zipcode || "...").concat(' ', (viewModel.appointment.city || "...")) + ' ' + viewModel.appointment.country || "..."} />
                        </InfoRowBox>
                    </Introduction>
                    <Form>
                        <h3>
                            <Text value="CHECKPOINT_EDIT_TITLE" />
                        </h3>
                        <Error value={viewModel.pageError} />
                        <Error value={viewModel.error} />
                        <MarginBox bottom={"20px"}>
                            <Text value="CHECKPOINT_EDIT_TEXT_DATE" />
                        </MarginBox>
                        <Button onClick={
                            (e) => {
                                e.preventDefault();
                                viewModel.onShowFilter(2);
                            }
                        }>
                            {
                                viewModel.filter.date 
                                ? <Text text={Moment(viewModel.filter.date).format('DD-MM-YYYY')}/>      
                                : <Text value="CHECKPOINT_EDIT_BUTTON_DATE"/>
                            }
                        </Button>
                        {
                            viewModel.filter.date
                            ?   [<MarginBox key={"MarginBox"} top={"32px"} bottom={"20px"}>
                                    <Text value="CHECKPOINT_EDIT_TEXT_TIME" />
                                </MarginBox>,
                                <Button key={"Button"} onClick={
                                    (e) => {
                                        e.preventDefault();
                                        viewModel.onShowFilter(3);
                                    }
                                }>
                                    {
                                        viewModel.filter.time 
                                        ? <Text text={Moment("1970-01-01T" + viewModel.filter.time).format('HH:mm')}/>           
                                        : <Text value="CHECKPOINT_EDIT_BUTTON_TIME"/>
                                    }
                                </Button>]
                            : ''
                        }
                        {
                            viewModel.filter.time
                            ?   [<MarginBox key={"MarginBox"} top={"32px"} bottom={"20px"}>
                                    <Text value="CHECKPOINT_EDIT_TEXT_SAVE" />
                                </MarginBox>,
                                <Button key={"Button"} onClick={
                                    (e) => {
                                        e.preventDefault();
                                        viewModel.onSubmit();
                                    }
                                }>
                                    <Text value="CHECKPOINT_EDIT_BUTTON_SAVE"/>
                                </Button>]
                            : ''
                        }
                    </Form>
                </FlexBox>
                : ''
            }
        </Page>
    ];
}

export default CheckpointEdit;