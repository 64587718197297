import styled from 'styled-components';

const Title = styled.h1`
    user-select: none;
    font-size: 42px;
    padding: 10px 0px;
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 105%;
    min-height: 40px;
    mix-blend-mode: normal;
    word-wrap: break-word;
`;

export default Title;