const configurationReducer = (state, action) => {
  switch (action.type) {
    case "setConfiguration": {
      return {
        ...state,
        configuration: applyEnvironment(action.data),
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
export default configurationReducer;

function applyEnvironment(data) {
  data.urls.identity = getEnvironmentItem("REACT_APP_URLS_IDENTITY");
  data.urls.professional = getEnvironmentItem("REACT_APP_URLS_PROFESSIONAL");
  return data;
}

function getEnvironmentItem(name) {
  if (!process.env[name]) {
    console.error(`The Environment Variable [${name}] does not exist.`);
    return null;
  }

  return process.env[name];
}