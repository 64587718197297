import React, { useContext, useState } from "react";

import useLocalizationDataManager from './useLocalizationDataManager';

import {ConfigurationContext} from '../Configuration/ConfigurationState';
import {AppContext} from '../App/AppState';

export const LocalizationContext = React.createContext();

export const LocalizationProvider = ({children}) => {

    const [locale, setLocale] = useState("nl");
    const [nextLocale, setNextLocale] = useState("en");

    const { configurationProvider } = useContext(ConfigurationContext);
    const { appProvider } = useContext(AppContext);

    const {
        isLoading,
        localizations
    } = useLocalizationDataManager(appProvider, configurationProvider.localizations);

    const provider = {
        localizationProvider: {
            getLocalization,
            toggleLocalization,
            locale: locale,
            nextLocale: nextLocale
        },
        isLoading
    };

    // Get the correct text value for a 'value' for the current Localization. If the 'value' is not found, it will return the 'fallbackValue'. The 'value' will be retured if no 'fallbackValue' was provided.
    function getLocalization(value, fallbackValue) {
        if(!value) {
            return fallbackValue;
        }
        else if(!localizations[locale][value]) {
            console.warn(`Localization value [${value}] was not found for locale [${locale}].`);
            return fallbackValue ?? value;
        }
        else {
            return localizations[locale][value];
        }
    }

    // Toggle to the next available Localization.
    function toggleLocalization() {
        // Check if we have more them 1 Localization.
        if(configurationProvider.localizations.length <= 1) {
            setLocale(configurationProvider.localizations[0]);
            setNextLocale(configurationProvider.localizations[0]);
            return;
        }
        // Get the current Localization index.
        let index = configurationProvider.localizations.indexOf(locale);
        // Check if the current Localization was found.
        if(index === -1) {
            // Current Localization was not found, so reset everuthing to the initial valuesl
            setLocale(configurationProvider.localizations[0]);
            setNextLocale(configurationProvider.localizations[1]);
            return
        }
        // Add 1 to the current index.
        index++;
        // Check if the index is not higher then the total number of Localizations.
        if(index >= configurationProvider.localizations.length) {
            // Index is higher then the total number of Localizations. Wrap around to initial values.
            setLocale(configurationProvider.localizations[0]);
            setNextLocale(configurationProvider.localizations[1]);
            return;
        }
        // Index has an ok value, so set the new localization.
        setLocale(configurationProvider.localizations[index]);
        // Add 1 to the current index.
        index++;
        // Check if the index is not higher then the total number of Localizations.
        if(index >= configurationProvider.localizations.length) {
            // Index is higher then the total number of Localizations. Wrap around to initial value.
            setNextLocale(configurationProvider.localizations[0]);
            return;
        }
        // Index has an ok value, so set the new next localization.
        setNextLocale(configurationProvider.localizations[index]);
    }
    
    return (
        <LocalizationContext.Provider value={provider}>
            {isLoading ? '' : children}
        </LocalizationContext.Provider>
    )
};