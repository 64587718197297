import styled from 'styled-components';

const Introduction = styled.div`
    color: var(--color-black);
    box-shadow: 0 0 30px var(--color-shadow);  
    display: flex;
    flex-direction: column;
    mix-blend-mode: normal;
    padding: 64px;
    width: 50%;



    @media (max-width: 820px) {
        width: 100%;
    }
`;

export default Introduction;