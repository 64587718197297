import React, {useContext} from "react";

import useConfigurationDataManager from './useConfigurationDataManager';

import {AppContext} from '../App/AppState';

export const ConfigurationContext = React.createContext();

export const ConfigurationProvider = ({children}) => {

    const { appProvider } = useContext(AppContext);

    const {
        isLoading,
        configuration
    } = useConfigurationDataManager(appProvider);

    const provider = {
        isLoading,
        configurationProvider: configuration
    };

    return (
        <ConfigurationContext.Provider value={provider}>
            {isLoading ? '' : children}
        </ConfigurationContext.Provider>
    )
};