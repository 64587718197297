import { useEffect, useReducer, useState } from 'react';

import axios from 'axios';

import { delay } from '../../utils/common';

import localizationReducer from './localizationReducer';

function useLocalizationDataManager(appProvider, locales) {

  const [dependancies] = useState({
    locales: locales,
    appProvider: appProvider
  });

  const [
    {
      isLoading,
      localizations,
      hasErrored,
      error
    },
    dispatch,
  ] = useReducer(localizationReducer, {
    isLoading: true,
    localizations: null,
    hasErrored: false,
    error: null
  });

  useEffect(() => {
    const fetchData = async function (locales, appProvider) {
      try {
        appProvider.addLoader();
        let data = {};        
        for (let index = 0; index < locales.length; index++) {
          const element = locales[index];
          let result = await axios.get(`/localizations/lang-${element}.json`);
          await delay(1);
          data[element] = result.data;
        }
        dispatch({ type: 'setLocalization', data: data });
      } catch (e) {
        dispatch({ type: 'errored', error: e });
      }
      finally {
        appProvider.removeLoader();
      }
    };

    fetchData(dependancies.locales, dependancies.appProvider);

    return () => {
    };
  }, [dependancies]);

  return {
    isLoading,
    localizations,
    hasErrored,
    error
  };
}
export default useLocalizationDataManager;