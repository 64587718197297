import React, {useState} from "react";
import styled from "styled-components";

import { ResultManager, ResultReader, DatasetManager, DatasetReader, AppointmentManager, AppointmentReader } from '../../core/constants/Roles';

export const AppContext = React.createContext();

export const AppProvider = ({children}) => {

    var parameters = {};

    const [loadCount, setLoadCount] = useState(0);
    const [textSize, setTextSize] = useState(1);
    const [globalError, setGlobalError] = useState(null);
    const [sections] = useState([
        {
            route: "reports",
            roles: [ResultManager, ResultReader],
            title: "HOME_REPORTS_TITLE",
            help: "HOME_REPORTS_HELP",
            index: 0
        },
        {
            route: "biometrics",
            roles: [DatasetManager, DatasetReader],
            title: "HOME_BIOMETRICS_TITLE",
            help: "HOME_BIOMETRICS_HELP",
            index: 1
        },
        {
            route: "labs",
            roles: [DatasetManager, DatasetReader],
            title: "HOME_LAB_TITLE",
            help: "HOME_LAB_HELP",
            index: 2
        },
        {
            route: "checkpoints",
            roles: [AppointmentManager, AppointmentReader],
            title: "HOME_APPOINTMENTS_TITLE",
            help: "HOME_APPOINTMENTS_HELP",
            index: 3
        }
    ]); 

    const provider = {
        appProvider: {
            globalError,
            textSize,
            setGlobalError,
            addLoader,
            removeLoader,
            clearLoader,
            setTextSize,
            setParameter,
            getParameter,
            clearParameters,
            sections
        }
    };

    function addLoader() {
        setLoadCount(loadCount + 1);
    }

    function removeLoader() {
        if(loadCount <= 1)setLoadCount(0);
        else setLoadCount(loadCount - 1);
    }

    function clearLoader() {
        setLoadCount(0);
    }

    // Clears all query parameters.
    function clearParameters() {
        parameters = {};
    }

    // Adds a new query parameter.
    function setParameter(key, value) {
        key = key.toLowerCase();
        // Only allow expected parameters!
        switch (key) {
          case "rfid":
          case "cc":
          case "email":
            break;
          default:
            console.warn("Unsupported query parameter found: [" + key + "]");
            return;
        }
        parameters[key] = value;
    }

    function getParameter(key, clear = false) {
        var value = parameters[key];
        if(clear) setParameter(key, null);
        return value;
    }

    return (
        <AppContext.Provider value={provider}>
            {(loadCount <= 0) ? '' : 
                <LoadingScreen>
                    <div className="loader"></div>
                </LoadingScreen>
            }
            {children}
        </AppContext.Provider>
    )
};

const LoadingScreen = styled.div`
    background: var(--color-overlay); 
    width:      100%;
    height:     100%; 
    z-index:    10;
    top:        0; 
    left:       0; 
    position:   fixed; 
    align-items: center;
    justify-content: center;
    display: flex;

`;