
import styled from 'styled-components';
import FlexBox from '../../../components/containers/FlexBox';

export const DataSetFlexBox = styled(FlexBox)`
  @media (max-width: 820px) {
    flex-direction:row;
    gap: 10px;
    width: 100%;
  }
`;

export const DataSetBox = styled(FlexBox)`
  width: 100%;
`;