import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Rule from "./Rule";

import Text from "../../text/Text";

const StyledValidation = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #ecc2c2e5;
  border-radius: 2px;
  margin-bottom: 10px;
  color: var(--color-error);
  font-size: 12px;
  span {
    margin: 0 25px;
  }
  .titleErr {
    display: flex;
    span {
      margin: 0 10px;
    }
    flex-direction: row;
    align-items: self-start;
    padding: 5px;
    position: static;
    align-self: stretch;
    color: ${(props) => (props.color ? props.color : "var(--color-error)")};

    img {
      margin: 5px 0px 0px 15px;
      width: 8px;
      font-size: 12px;
    }
  }
`;
const isPasswordLength = (string) => string.length > 7;
const hasLowerCase = (string) => /[a-z]/.test(string);
const hasUpperCase = (string) => /[A-Z]/.test(string);
const hasNumber = (string) => /[0-9]/.test(string);
const hasSpecialCharacter = (string) => /[^a-z\d]+/i.test(string);
const usernameNotInpassword = (username, password) => !password.includes(username);
const PasswordValidation = (props) => {
  const [lowercase, setLowercase] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  const [special, setSpecial] = useState(false);

  const [usernameInPassword, setUsernameInPassword] = useState(false);

  const [oldnewpsw, setOldnewpsw] = useState(false);

  const onChange = (name, value) => {
    switch (name) {
      case "lowercase":
        setLowercase(value);
        break;
      case "uppercase":
        setUppercase(value);
        break;
      case "number":
        setNumber(value);
        break;
      case "passwordLength":
        setPasswordLength(value);
        break;
      case "special":
        setSpecial(value);
        break;
      case "usernameInPassword":
        setUsernameInPassword(value);
        break;
      case "oldnewpsw":
        setOldnewpsw(value);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const { onChange } = props;
    if (passwordLength && lowercase && uppercase && number && special && (props.username ? usernameInPassword : true) && (props.passwordOld ? oldnewpsw : true)) {
      onChange(true);
    } else {
      onChange(false);
    }
  }, [props.password, passwordLength, lowercase, uppercase, number, special, usernameInPassword, oldnewpsw]);

  const { username, password, passwordOld } = props;
  return (
    <StyledValidation>
      <div className="titleErr">
        <img src={props.closeIcon} alt="check" />
        <Text value="PASSWORD_REQUIREMENTS" />
      </div>

      <Rule name="lowercase" valid={lowercase} onChange={onChange} password={password} rule={({ password }) => hasLowerCase(password)} text="MIN_1_LOWER_CASE_LETTER" />
      <Rule name="uppercase" valid={uppercase} onChange={onChange} password={password} rule={({ password }) => hasUpperCase(password)} text="MIN_1_UPPER_CASE_LETTER" />
      <Rule name="passwordLength" valid={passwordLength} onChange={onChange} password={password} rule={({ password }) => isPasswordLength(password)} text="MIN_N_CHARACTERS" />
      <Rule name="number" valid={number} onChange={onChange} password={password} rule={({ password }) => hasNumber(password)} text="MIN_1_NUMBER" />
      <Rule name="special" valid={special} onChange={onChange} password={password} rule={({ password }) => hasSpecialCharacter(password)} text="MIN_1_SPECIAL_CHAR" />
      {username && (
        <Rule
          name="usernameInPassword"
          valid={usernameInPassword}
          onChange={onChange}
          password={password}
          username={username}
          rule={({ username, password }) => usernameNotInpassword(username, password)}
          text="USERNAME_NOT_IN_PASSWORD"
        />
      )}
      {passwordOld && <Rule name="oldnewpsw" valid={oldnewpsw} onChange={onChange} password={password} rule={({ password }) => password !== passwordOld} text="NOT_BE_THE_SAME" />}
    </StyledValidation>
  );
};

export default PasswordValidation;
