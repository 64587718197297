import React, { useEffect } from "react";
import Input from "./Input";
import styled from "styled-components";

const StyledScanner = styled.div`
  width: 100%;

  input {
    background: #cccccc63;
    cursor: pointer;
    :focus {
      outline-width: 0;

      color: transparent;
      text-shadow: 0px 0px 0px #666;
    }
  }
`;
const ScannerInput = ({ scannedValue, setScannedValue }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      // add scan property to window if it does not exist
      if (!window.hasOwnProperty("scan")) {
        window.scan = [];
      }

      // if key stroke appears after 10 ms, empty scan array
      if (window.scan.length > 0 && e.timeStamp - window.scan.slice(-1)[0].timeStamp > 10) {
        window.scan = [];
      }

      // if key store is enter and scan array contains keystrokes
      // dispatch `scanComplete` with keystrokes in detail property
      // empty scan array after dispatching event
      if (e.key === "Enter" && window.scan.length > 0) {
        let scannedString = window.scan.reduce((scannedString, entry) => {
          return scannedString + entry.key;
        }, "");
        window.scan = [];
        setScannedValue(scannedString);
      }

      // do not listen to shift event, since key for next keystroke already contains a capital letter
      // or to be specific the letter that appears when that key is pressed with shift key
      if (e.key !== "Shift") {
        // push `key`, `timeStamp` and calculated `timeStampDiff` to scan array
        let data = JSON.parse(JSON.stringify(e, ["key", "timeStamp"]));
        data.timeStampDiff = window.scan.length > 0 ? data.timeStamp - window.scan.slice(-1)[0].timeStamp : 0;

        window.scan.push(data);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <StyledScanner>
      {" "}
      <Input border="1px solid #cccc" height="40px" className="barcodeScanner" autoFocus type="text" value={scannedValue} readOnly />
    </StyledScanner>
  );
};

export default ScannerInput;
