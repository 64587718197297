import styled from 'styled-components';
import { Large, Medium } from '../../core/enums/TextSize';

import { getColor, getHeaderColor, getIconPadding } from '../../utils/report';

export const HealthSummary = styled.div`
    color: ${props => getHeaderColor(props.value, false, props.checkCVD)};
    background-color: ${props => getHeaderColor(props.value, true, props.checkCVD)};
    display: flex;
    align-items: center;    
    font-weight: 400;
    padding 16px;
    width: 100%;
    height: fit-content;

    svg g {
        stroke: ${props => getHeaderColor(props.value, false, props.checkCVD)};
    }

    svg > circle {
        fill: ${props => getHeaderColor(props.value, false, props.checkCVD)};
    }

    svg {
        padding: 4px;
        margin: 5px 8px;
        min-width: 30px;
        height: 30px;
    }

    @media (max-width: 820px) {
        margin: 12px 0;
    }
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Status = styled.div`
  color: ${props => getColor(props.value, false)};
  background-color: ${props => getColor(props.value, true)};
  padding: 5px 8px 4px;
  font-size:14px;
`;

export const Icon = styled.div`
  background-color: ${props => getColor(props.value, true)};
  padding: ${props => getIconPadding(props.index)};
  width: 35px;
  height: 35px;
  margin: 3px;
`;

export const Category = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  position: relative;
  width: ${props => props.textSize === Large ? "380px" : (props.textSize === Medium) ? "275px" : "190px"};
  height: 180px;
  background: var(--color-white);
  border: 1px solid;
  border-color: var(--color-medium-grey);
  box-sizing: border-box;
  margin: 0px 10px 10px 0px;

  > span {
    font-weight: bold;
  }

  :hover {
    opacity:0.75;
  }
`;