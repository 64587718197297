import React from 'react';

import Page from '../../../components/containers/Page';
import FlexBox from '../../../components/containers/FlexBox'
import Error from '../../../components/containers/Error'
import Button from '../../../components/buttons/Button'
import Title from '../../../components/text/Title'
import Subtitle from '../../../components/text/Subtitle'
import Text from '../../../components/text/Text'
import Input from '../../../components/inputs/Input'

import { Secondary } from '../../../core/enums/ButtonType';
import { ButtonBox, InputField, InputGroup } from '../Datasets.Components';

import { AttributeIds, getAttribute, getLabelTitle, hasDataset } from '../../../utils/dataset';

import BiometricViewModel from './Biometric.VM';

const Biometric = () => {
  const {
    viewModel
  } = BiometricViewModel();

  return (    
    <Page>
      <Title>
        <Text text={viewModel.nipedNumber} />
      </Title>
      <Error value={viewModel.pageError} />
      <Error value={viewModel.error} />
      {
        (hasDataset(viewModel.attributes, "A8FD163A-306D-4996-A1C7-939299D42DE5") || hasDataset(viewModel.attributes, "8C9A4ED4-5EC1-4707-B61D-122867F14A2D")) 
        ? [
            <Subtitle key={"SubTitle"}>
              <Text value="BIOMETRIC_TITLES_FYSICAL_MEASUREMENTS" />
            </Subtitle>,
            <FlexBox key={"FlexBox"}>
              {
                /* Fysical Measurements */
                (hasDataset(viewModel.attributes, "A8FD163A-306D-4996-A1C7-939299D42DE5")) 
                ? [
                    <InputField key={AttributeIds.Height} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Height).isInvalid}>
                      <Text value={getLabelTitle(AttributeIds.Height)} />
                      <Input maxLength="3"
                             value={getAttribute(viewModel.attributes, AttributeIds.Height).value}
                             onChange={(e) => viewModel.onChange(AttributeIds.Height, e, 0)}
                             onInput={(e) => viewModel.onInput(AttributeIds.Height, e) }/>
                    </InputField>,
                    <InputField key={AttributeIds.Weight} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Weight).isInvalid}>
                      <Text value={getLabelTitle(AttributeIds.Weight)} />
                      <Input maxLength="3"
                             value={getAttribute(viewModel.attributes, AttributeIds.Weight).value}
                             onChange={(e) => viewModel.onChange(AttributeIds.Weight, e, 0)}
                             onInput={(e) => viewModel.onInput(AttributeIds.Weight, e) }/>
                    </InputField>
                  ]
                : ''
              }
              {
                /* Abdominal Measurements */
                (hasDataset(viewModel.attributes, "8C9A4ED4-5EC1-4707-B61D-122867F14A2D"))
                ? <InputField key={AttributeIds.Waist} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Waist).isInvalid}>
                    <Text value={getLabelTitle(AttributeIds.Waist)} />
                    <Input maxLength="3"
                           value={getAttribute(viewModel.attributes, AttributeIds.Waist).value}
                           onChange={(e) => viewModel.onChange(AttributeIds.Waist, e, 0)}
                           onInput={(e) => viewModel.onInput(AttributeIds.Waist, e) }/>
                  </InputField>
                : ''
              }
            </FlexBox>
          ]
          : ''
      }
      {
        /* Blood Pressure Measurements */
        (hasDataset(viewModel.attributes, "8D12B88D-48BC-47C3-B4C1-7B0FC49BF412")) 
        ? [
            <Subtitle key={"SubTitle"}>
              <Text value="BIOMETRIC_TITLES_BLOODPRESSURE_MEASUREMENTS" />
            </Subtitle>,
            <FlexBox key={"FlexBox"}>
              <InputGroup>
                <Text value="BIOMETRIC_TITLES_BLOODPRESSURE_MEASUREMENT_1" />
                <InputField key={AttributeIds.Systolic} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Systolic, 0).isInvalid}>
                  <Text value={getLabelTitle(AttributeIds.Systolic)} />
                  <Input maxLength="3"
                         value={getAttribute(viewModel.attributes, AttributeIds.Systolic, 0).value}
                         onChange={(e) => viewModel.onChange(AttributeIds.Systolic, e, 0)}
                         onInput={(e) => viewModel.onInput(AttributeIds.Systolic, e) }/>
                </InputField>
                <InputField key={AttributeIds.Diastolic} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Diastolic, 0).isInvalid}>
                  <Text value={getLabelTitle(AttributeIds.Diastolic)} />
                  <Input maxLength="3"
                         value={getAttribute(viewModel.attributes, AttributeIds.Diastolic, 0).value}
                         onChange={(e) => viewModel.onChange(AttributeIds.Diastolic, e, 0)}
                         onInput={(e) => viewModel.onInput(AttributeIds.Diastolic, e) }/>
                </InputField>
                <InputField key={AttributeIds.Pulse} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Pulse, 0).isInvalid}>
                  <Text value={getLabelTitle(AttributeIds.Pulse)} />
                  <Input maxLength="3"
                         value={getAttribute(viewModel.attributes, AttributeIds.Pulse, 0).value}
                         onChange={(e) => viewModel.onChange(AttributeIds.Pulse, e, 0)}
                         onInput={(e) => viewModel.onInput(AttributeIds.Pulse, e) }/>
                </InputField>
              </InputGroup>
              <InputGroup>
                <Text value="BIOMETRIC_TITLES_BLOODPRESSURE_MEASUREMENT_2" />
                <InputField key={AttributeIds.Systolic} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Systolic, 1).isInvalid}>
                  <Text value={getLabelTitle(AttributeIds.Systolic)} />
                  <Input maxLength="3"
                         value={getAttribute(viewModel.attributes, AttributeIds.Systolic, 1).value}
                         onChange={(e) => viewModel.onChange(AttributeIds.Systolic, e, 1)}
                         onInput={(e) => viewModel.onInput(AttributeIds.Systolic, e) }/>
                </InputField>
                <InputField key={AttributeIds.Diastolic} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Diastolic, 1).isInvalid}>
                  <Text value={getLabelTitle(AttributeIds.Diastolic)} />
                  <Input maxLength="3"
                         value={getAttribute(viewModel.attributes, AttributeIds.Diastolic, 1).value}
                         onChange={(e) => viewModel.onChange(AttributeIds.Diastolic, e, 1)}
                         onInput={(e) => viewModel.onInput(AttributeIds.Diastolic, e) }/>
                </InputField>
                <InputField key={AttributeIds.Pulse} isInvalid={getAttribute(viewModel.attributes, AttributeIds.Pulse, 1).isInvalid}>
                  <Text value={getLabelTitle(AttributeIds.Pulse)} />
                  <Input maxLength="3"
                         value={getAttribute(viewModel.attributes, AttributeIds.Pulse, 1).value}
                         onChange={(e) => viewModel.onChange(AttributeIds.Pulse, e, 1)}
                         onInput={(e) => viewModel.onInput(AttributeIds.Pulse, e) }/>
                </InputField>
              </InputGroup>
            </FlexBox>
          ]
        : ''
      }
      {
        (viewModel.attributes && viewModel.attributes.length > 0) 
        ? <ButtonBox>
            <Button styleType={Secondary}
                    onClick={(e) => {
                      e.preventDefault();
                      viewModel.onBack();
                    }}>
              <Text value="GENERAL_BUTTON_BACK" />
            </Button>  
            <Button onClick={(e) => {
                      e.preventDefault();
                      viewModel.onSubmit();
                    }}>
              <Text value="GENERAL_BUTTON_SAVE" />
            </Button>
          </ButtonBox>
        : ''
    }
    </Page>
  );
}

export default Biometric;