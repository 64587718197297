import styled from 'styled-components';

import { Container, ModalScreen } from '../../components/modals/Modal';

export const PopUpScreen = styled(ModalScreen)`
  display: ${props => props.visible ? 'flex' : 'none'};
`;

export const PopUp = styled(Container)`
  width: 480px;
`;

export const Item = styled.div`
    width: 100%;
    color: var(--color-black);
    cursor: pointer;
    margin-top: 32px;
    
    > div {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 1024px) {
        width: calc(50% - 6px);
        
        > div {
            display: inherit;
            flex-direction: row;
        }

        :hover {
            margin-top: 16px;
        }
    }
    @media (max-width: 580px) {
        width: 100%;
    }
`;

export const ItemHeader = styled.div`
    display: flex;
    width: 30%;
    background-color: var(--color-primary);
    align-items: center;
    color: var(--color-white);
    flex-direction: column;
    padding: 0px 12px 16px 12px;
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const ItemRow = styled.div`
    display: flex;
    width: calc(70% / 3);
    background-color: ${props => props.isEven ? "var(--color-light-grey)" : "transparant"};
    align-items: center;
    flex-direction: column;
    padding: 16px 12px 16px 12px;
    flex-direction: column;
    text-align: center;

    span {
        height: 50%;
        text-transform: uppercase;
        font-weight:light;
        font-size:12px;                    
    }
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;