import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Text from '../text/Text';
import Title from '../text/Title';
import ButtonRow from '../containers/ButtonRow';
import Button from '../buttons/Button';
import MarginBox from '../containers/MarginBox';

import {Primary, Secondary} from '../../core/enums/ButtonType'

const Modal = ({title, text, elements, onConfirm, confirmButtonText, onCancel, cancelButtonText, onClose}) => {
    return (
        <ModalScreen>
            <div>
                <Container>
                    {!title ? '' :
                        <Title>
                            <Text value={title} />
                        </Title>
                    }
                    {!text ? '' :
                        <Text value={text} />
                    }
                    {!elements || elements.length === 0 ? '' :
                        elements.map((e, index) => (
                            <Text key={index} text={e.value} />
                        ))
                    }

                    {!onClose ? '' :
                        <MarginBox top="16px">
                            <CloseButton onClick={onClose}>
                                <FontAwesomeIcon icon={faTimes} />
                            </CloseButton>
                        </MarginBox>
                    }

                    {!onConfirm && !onCancel ? '' :                    
                        <MarginBox top="16px">
                            <ButtonRow>
                                {!onCancel ? '' :
                                    <Button styleType={Primary} onClick={onCancel}>
                                        <Text value={cancelButtonText ? cancelButtonText : "GENERAL_BUTTON_CANCEL"}/>
                                    </Button>
                                }
                                {!onConfirm ? '' :
                                    <Button styleType={Secondary} onClick={onConfirm}>
                                        <Text value={confirmButtonText ? confirmButtonText : "GENERAL_BUTTON_OK"}/>
                                    </Button>
                                }
                            </ButtonRow>
                        </MarginBox>
                    }
                </Container>
            </div>
        </ModalScreen>
    )
}
export default Modal;

export const ModalScreen = styled.div`
    background: rgba(255,255,255,0.7);
    width:      100%;
    height:     100%; 
    top:        0; 
    left:       0; 
    position:   fixed; 
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 999999;
    display: fixed;
`;

export const Container = styled.div`
    position: relative;
    animation: 0.3s ease 0s 1 normal none running hNgNAr;
    margin: 50px;
    width: 95%;
    bottom: 0px;
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    background-color: var(--color-white);
    box-shadow: 0 0 30px var(--color-shadow);  
    flex-direction: column;
    mix-blend-mode: normal;
    padding: 48px 64px 44px 64px;

    @media (min-width: 992px) {
        width: 1000px;
        height: auto;
        bottom: inherit;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color-button-primary);
        border: 1px solid var(--color-button-primary);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--color-white);
    }
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 26px;
    top: 20px;
    font-size: 22px;
    cursor: pointer;
    z-index: 1;

    > svg path {
        fill: var(--color-button-primary);
    }
`