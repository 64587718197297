import styled from 'styled-components';

const Page = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    min-height: 447px;
    width: 40%;
    margin: auto;

    @media (min-width: 1024px) {
        min-width:820px;
    }

    @media (max-width: 1024px) {
        width: 100%;
        max-width:820px;
    }
`;

export default Page;