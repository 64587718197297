import React, {useContext} from 'react';
import styled from 'styled-components';

import {LocalizationContext} from '../../global/Localization/LocalizationState';

const LanguageToggleButton = () => {

    const { localizationProvider } = useContext(LocalizationContext);

    return (
        <Container>
            <img src={"/library/logos/" + localizationProvider.nextLocale + ".svg"}
                alt={localizationProvider.nextLocale}
                onClick={() => {
                    localizationProvider.toggleLocalization();
                }} 
            />
        </Container>
    )
}
export default LanguageToggleButton;

const Container = styled.div`
    img {
        height: 25px;
        width: 25px;
        cursor: pointer;
        border-radius: 50%;
        object-fit: cover;
    }
`;