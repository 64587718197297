import React from 'react';
import styled from 'styled-components';

import Text from '../../components/text/Text';

const Success = ({value}) => {
    if(!value) return "";
    
    return (   
        <Container>
            <div/>
            <Text value={value} />
        </Container>
    );
}

export default Success;

export const SuccessText = ({ value }) => {
  if (!value) return "";

  return (
    <StyledSuccessText>
      <div />
      <Text value={value} />
    </StyledSuccessText>
  );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    position: static;
    background: rgb(227, 239, 200);
    border-radius: 10px;
    margin: 18px 0px;
    align-self: stretch;
    color: rgb(123, 166, 30);

    > div {
        margin: 5px 8px;
        min-width: 30px;
        height: 30px;
        background-color: rgb(123, 166, 30);
        -webkit-mask: url(/library/images/success.svg) center center no-repeat;
    }

    span {
        font-weight: 400;
        margin: auto 0px;
    }
`;
const StyledSuccessText = styled.div`
  color: rgb(123, 166, 30);
  text-align: center;
`;