import { useContext, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { ModalContext } from "../../global/Modal/ModalState";
import { StorageContext } from "../../global/Storage/StorageState";

import { getAppointment } from "../../utils/checkpoints";

const CheckpointViewModel = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { storageProvider } = useContext(StorageContext);
  const { professionalWebService, error } = useContext(WebServiceContext);
  const { modalProvider } = useContext(ModalContext);

  const [appointment] = useState(getAppointment(storageProvider.checkpoints, id));
  const [pageError, setPageError] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [dob, setDateOfBirth] = useState("");
  const [isValid, setValid] = useState(null);
  const [scannedValue, setScannedValue] = useState("");
  const [message, setMessage] = useState(null);

  async function onCancel(confirmed = false) {
    if (confirmed === false) {
      modalProvider.showModal(
        "CHECKPOINT_MODAL_CANCEL_TITLE",
        "CHECKPOINT_MODAL_CANCEL_TEXT",
        null,
        () => {
          modalProvider.hideModal();
          onCancel(true);
        },
        modalProvider.hideModal,
        "GENERAL_YES",
        "GENERAL_NO"
      );
    }

    if (confirmed === true) {
      setIsBusy(true);
      let result = await professionalWebService.deleteCheckpoint(appointment.checkpointGlobalId, appointment.appointmentGlobalId, appointment.nipedNumber);

      setIsBusy(false);

      // 204 NoContent is returned.
      if (result === "") {
        modalProvider.showModal("CHECKPOINT_MODAL_CANCELLED_TITLE", "CHECKPOINT_MODAL_CANCELLED_DESCRIPTION", null, onPrevious, null, null, null, onPrevious);
        storageProvider.clearCheckpoints();
      } else modalProvider.showModal("CHECKPOINT_MODAL_ERROR_TITLE", "CHECKPOINT_MODAL_ERROR_DESCRIPTION", null, modalProvider.hideModal);
    }
  }

  async function validateDob() {
    setIsBusy(true);
    if (dob === "") {
      setMessage("DATE_OF_BIRTH_REQUIRED");
      return setIsBusy(false);
    }
    let result = await professionalWebService.validateDob(appointment.checkpointGlobalId, appointment.appointmentGlobalId, appointment.nipedNumber, dob);

    if (result.boolean) {
      setValid(true);
      setMessage("VALIDATED_CORRECT");
    } else setMessage("NOT_VALIDATED");

    setIsBusy(false);
  }
  async function assignBarcode() {
    setIsBusy(true);
    setMessage(null);
    let result = await professionalWebService.postBarcode(appointment.checkpointGlobalId, appointment.appointmentGlobalId, appointment.nipedNumber, scannedValue);

    if (result.status === 409) {
      setMessage("BARCODE_ALREADY_SAVED");
    } else if (result.status === 400) {
      setMessage("ERROR_SAVING_BARCODE");
    } else if (result.status === 404) {
      setMessage("ERROR_SHIPMENT_NOT_FOUND");
    } else if (result.status === 406) {
      setMessage("ERROR_WITH_SHIPMENT_STATE");
    } else if (result.barcode) {
      setMessage("BARCODE_SAVED_SUCCESS");
      const checkpoint = storageProvider.checkpoints
        .find((cp) => cp.globalId === appointment.checkpointGlobalId)
        .appointments.data.find((a) => a.appointmentGlobalId === appointment.appointmentGlobalId);
      if (checkpoint) {
        if (!checkpoint.barcodes) {
          checkpoint.barcodes = [];
        }
        checkpoint.barcodes.push(result.barcode);
      }
    }
    setIsBusy(false);
  }

  function onChange() {
    navigate(`/checkpoint-edit/${id}`);
  }
  function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr) && new Date(dateStr).getFullYear() > 1900);
  }
  function setDob(e) {
    setDateOfBirth(e);
    setMessage(null);
    setScannedValue("");
    if (isDateValid(dob)) {
      setValid(false);
    }
  }

  function onPrevious() {
    modalProvider.hideModal();
    navigate(-1);
  }

  return {
    viewModel: {
      appointment: appointment,
      onCancel: onCancel,
      validateDob: validateDob,
      onChange: onChange,
      dob: dob,
      setDob: setDob,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
      isValid: isValid,
      assignBarcode: assignBarcode,
      scannedValue: scannedValue,
      setScannedValue: setScannedValue,
      navigate: navigate,
      message: message,
    },
  };
};

export default CheckpointViewModel;
