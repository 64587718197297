import React from 'react';

import Button from '../../../components/buttons/Button'
import Page from '../../../components/containers/Page';
import Error from '../../../components/containers/Error'
import MarginBox from '../../../components/containers/MarginBox';
import Title from '../../../components/text/Title'
import Subtitle from '../../../components/text/Subtitle'
import Text from '../../../components/text/Text'
import Input from '../../../components/inputs/Input'

import { DataSetBox, DataSetFlexBox } from './Lab.Components';

import { Secondary } from '../../../core/enums/ButtonType';
import { ButtonBox, InputField, InputGroup } from '../Datasets.Components';

import { getAttribute, getLabelTitle } from '../../../utils/dataset';

import LabViewModel from './Lab.VM';

const Lab = () => {
  const {
    viewModel
  } = LabViewModel();

  return (    
    <Page>
      <Title>
        <Text text={viewModel.nipedNumber} />
      </Title>
      <Error value={viewModel.pageError} />
      <Error value={viewModel.error} />
      {(viewModel.attributes && viewModel.attributes.length > 0) 
      ? [
          <Subtitle key={"SubTitle"}>
            <Text value="LAB_TITLE" />
          </Subtitle>,
          <MarginBox key={"MarginBox"} bottom={"32px"}>
            <Text value="LAB_TEXT" />
          </MarginBox>,
          <DataSetFlexBox key={"FlexBox"}>
            <DataSetBox key={"DataSetBox_1"}>
              {
                viewModel.inCheckMode
                ? ''
                : 
                  <InputGroup>
                    <Text value="LAB_TITLES_MEASUREMENT_1" />
                      { 
                        viewModel.attributes.map(a => 
                          <InputField key={a.id} isInvalid={getAttribute(viewModel.attributes, a.id, 0).isInvalid}>
                            <Text value={getLabelTitle(a.id)} />
                            <Input maxLength="6"
                                   value={getAttribute(viewModel.attributes, a.id, 0).value}
                                   onChange={(e) => viewModel.onChange(a.id, e, 0)}
                                   onInput={(e) => viewModel.onInput(a.id, e) }/>
                          </InputField>
                        )
                      }
                  </InputGroup>
              }
              
            </DataSetBox>
            <DataSetBox key={"DataSetBox_2"}>
              {
                viewModel.inCheckMode
                ? 
                  <InputGroup>
                    <Text value="LAB_TITLES_MEASUREMENT_2" />
                      {
                        viewModel.attributes.map(a => 
                          <InputField key={a.id} isInvalid={getAttribute(viewModel.attributes, a.id, 0).isInvalid}>
                            <Text value={getLabelTitle(a.id)} />
                            <Input maxLength="6"
                                   value={getAttribute(viewModel.attributes, a.id, 0).check}
                                   onChange={(e) => viewModel.onChangeCheck(a.id, e, 0)}
                                   onInput={(e) => viewModel.onInput(a.id, e) }/>
                          </InputField>
                        )
                      }
                  </InputGroup>
                : ''
              }
            </DataSetBox>
          </DataSetFlexBox>,
          <ButtonBox key={"ButtonBox"}>
            <Button styleType={Secondary}
                    onClick={(e) => {
                      e.preventDefault();
                      viewModel.onBack();
                    }}>
              <Text value="GENERAL_BUTTON_BACK" />
            </Button>  
            {
              viewModel.inCheckMode
              ?
                <Button onClick={(e) => {
                          e.preventDefault();
                          viewModel.onSubmit();
                        }}>
                  <Text value="GENERAL_BUTTON_SAVE" />
                </Button>
              :
                <Button onClick={(e) => {
                          e.preventDefault();
                          if(viewModel.validate())
                            viewModel.setInCheckMode(true);
                        }}>
                  <Text value="GENERAL_BUTTON_CONTINUE" />
                </Button>
          }
          </ButtonBox>
        ]
      : ''}
    </Page>
  );
}

export default Lab;
