import {useContext} from 'react';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import "../vendor/css/font-awesome.min.css"

import styled from "styled-components";

import ProtectedRoute from '../components/routes/ProtectedRoute'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer';
import Login from './login/Login'
import Home from './home/Home';
import Reports from './reports/Reports';
import Report from './report/Report';
import ReportPage from './report-page/ReportPage';
import Biometrics from './datasets/biometrics/Biometrics';
import Biometric from './datasets/biometric/Biometric';
import Labs from './datasets/labs/Labs';
import Lab from './datasets/lab/Lab';

import {AppContext} from '../global/App/AppState';

import { Professional, ResultManager, ResultReader, DatasetManager, DatasetReader, AppointmentManager, AppointmentReader } from '../core/constants/Roles';
import Checkpoints from './checkpoints/Checkpoints';
import Checkpoint from './checkpoint/Checkpoint';
import CheckpointEdit from './checkpoint-edit/CheckpointEdit';
import ScrollToTop from '../hooks/ScrollToTop';
import RegisterUser from "./registeruser/RegisterUser";

const App = () => {
  const { appProvider } = useContext(AppContext);

  new URLSearchParams(window.location.search).forEach(function(value, key) {
    appProvider.setParameter(key, value);
  });

  return (
    <Container border="0" cellSpacing="0">
      <tbody>
        <tr>
          <td colSpan={3}>
            <Header />
          </td>
        </tr>
        <tr></tr>
        <tr>
          <td />
          <td>
            <Content>
              <BrowserRouter>
                <ScrollToTop>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="password/create" element={<RegisterUser />} />
                    <Route path="login" element={<Login />} />
                    <Route
                      path="home"
                      element={
                        <ProtectedRoute roles={[Professional]}>
                          <Home />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="reports"
                      element={
                        <ProtectedRoute roles={[ResultManager, ResultReader]}>
                          <Reports />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="report/:id"
                      element={
                        <ProtectedRoute roles={[ResultManager, ResultReader]}>
                          <Report />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="report-page/:id"
                      element={
                        <ProtectedRoute roles={[ResultManager, ResultReader]}>
                          <ReportPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="biometrics"
                      element={
                        <ProtectedRoute roles={[DatasetManager, DatasetReader]}>
                          <Biometrics />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="biometric/:id"
                      element={
                        <ProtectedRoute roles={[DatasetManager, DatasetReader]}>
                          <Biometric />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="labs"
                      element={
                        <ProtectedRoute roles={[DatasetManager, DatasetReader]}>
                          <Labs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="lab/:id"
                      element={
                        <ProtectedRoute roles={[DatasetManager, DatasetReader]}>
                          <Lab />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="checkpoints"
                      element={
                        <ProtectedRoute roles={[AppointmentManager, AppointmentReader]}>
                          <Checkpoints />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="checkpoint/:id"
                      element={
                        <ProtectedRoute roles={[AppointmentManager, AppointmentReader]}>
                          <Checkpoint />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="checkpoint-edit/:id"
                      element={
                        <ProtectedRoute roles={[AppointmentManager]}>
                          <CheckpointEdit />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </ScrollToTop>
              </BrowserRouter>
            </Content>
          </td>
          <td />
        </tr>
        <tr></tr>
        <tr>
          <td colSpan={3}>
            <Footer />
          </td>
        </tr>
      </tbody>
    </Container>
  );
}

export default App;

const Container = styled.table`
  width: 100%;
  max-width: 100%;
  min-height: 100%;

  tr:first-child {
    height: 0;
  }

  tr:nth-child(2), tr:nth-child(4) {
    height: 16px;
  }

  tr:nth-child(3) td:nth-child(2) {
    overflow: hidden;
    padding: 6%;
    margin: 16px;
  }

  tr:nth-child(3) td:nth-child(2) > img {
    position: absolute;
    width: 20%;
    left: auto;
    right: 18px;
    z-index: 1;
    top: 88px;
  }

  tr:nth-child(3) td:first-child, tr:nth-child(3) td:last-child  {
    width:16px;
  }

  tr:last-child {
    height: 0;
  }

  td {
    padding: 0px;
    vertical-align: top;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 240px;
  z-index: 2;
`;