import React, { useContext } from "react";
import styled from "styled-components";

import Text from "../../components/text/Text";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

const Error = ({ value }) => {
  if (!value) return "";

  return (
    <Container>
      <Text value={value} />
    </Container>
  );
};

export default Error;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  // align-items: flex-start;
  position: static;
  border-radius: 10px;
  margin: 18px 0px;
  align-self: stretch;
  color: var(--color-error);

  span {
    font-weight: 400;
    margin: auto 0px;
  }
  img {
    margin-right: 15px;
  }
`;

export const ErrorValidation = (props) => {
  const { configurationProvider } = useContext(ConfigurationContext);

  if (!props.value) return "";
  return (
    <Container {...props}>
      <img src={configurationProvider.cdn + "client/close.svg"} alt="check" />

      <Text value={props.value} />
    </Container>
  );
};
