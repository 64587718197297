const HomeIcon = ({index, color}) => {
    switch (index) {
        case 0:
            return <Reports color={color}/>;
        case 1:
            return <Biometrics color={color}/>;
        case 2:
            return <Lab color={color}/>;
        case 3:
            return <Appointments color={color}/>;
        default:
            return '';
    }
};

export default HomeIcon;

const Reports = ({color}) => {
    return (
        <svg width="512pt" height="512pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="m400 512h-288c-12.727-0.015625-24.926-5.0742-33.926-14.074s-14.059-21.199-14.074-33.926v-416c0.015625-12.727 5.0742-24.926 14.074-33.926s21.199-14.059 33.926-14.074h272.67c4.3945 0.0039062 8.5977 1.8125 11.625 5l47.328 50c2.8125 2.9727 4.375 6.9102 4.375 11v398c-0.015625 12.727-5.0742 24.926-14.074 33.926s-21.199 14.059-33.926 14.074zm-288-480c-4.2422 0.003906-8.3086 1.6914-11.309 4.6914s-4.6875 7.0664-4.6914 11.309v416c0.003906 4.2422 1.6914 8.3086 4.6914 11.309s7.0664 4.6875 11.309 4.6914h288c4.2422-0.003906 8.3086-1.6914 11.309-4.6914s4.6875-7.0664 4.6914-11.309v-391.62l-38.219-40.375z"/>
                <path d="m432 96h-64c-4.2422 0-8.3125-1.6875-11.312-4.6875s-4.6875-7.0703-4.6875-11.312v-64c0-5.7148 3.0508-11 8-13.855 4.9492-2.8594 11.051-2.8594 16 0 4.9492 2.8555 8 8.1406 8 13.855v48h48c5.7148 0 11 3.0508 13.855 8 2.8594 4.9492 2.8594 11.051 0 16-2.8555 4.9492-8.1406 8-13.855 8z"/>
                <path d="m160 272c-4.2422 0-8.3125-1.6875-11.312-4.6875s-4.6875-7.0703-4.6875-11.312v-96c0-5.7148 3.0508-11 8-13.855 4.9492-2.8594 11.051-2.8594 16 0 4.9492 2.8555 8 8.1406 8 13.855v96c0 4.2422-1.6875 8.3125-4.6875 11.312s-7.0703 4.6875-11.312 4.6875z"/>
                <path d="m224 272c-4.2422 0-8.3125-1.6875-11.312-4.6875s-4.6875-7.0703-4.6875-11.312v-128c0-5.7148 3.0508-11 8-13.855 4.9492-2.8594 11.051-2.8594 16 0 4.9492 2.8555 8 8.1406 8 13.855v128c0 4.2422-1.6875 8.3125-4.6875 11.312s-7.0703 4.6875-11.312 4.6875z"/>
                <path d="m288 272c-4.2422 0-8.3125-1.6875-11.312-4.6875s-4.6875-7.0703-4.6875-11.312v-64c0-5.7148 3.0508-11 8-13.855 4.9492-2.8594 11.051-2.8594 16 0 4.9492 2.8555 8 8.1406 8 13.855v64c0 4.2422-1.6875 8.3125-4.6875 11.312s-7.0703 4.6875-11.312 4.6875z"/>
                <path d="m352 272c-4.2422 0-8.3125-1.6875-11.312-4.6875s-4.6875-7.0703-4.6875-11.312v-112c0-5.7148 3.0508-11 8-13.855 4.9492-2.8594 11.051-2.8594 16 0 4.9492 2.8555 8 8.1406 8 13.855v112c0 4.2422-1.6875 8.3125-4.6875 11.312s-7.0703 4.6875-11.312 4.6875z"/>
                <path d="m368 272h-224c-5.7148 0-11-3.0508-13.855-8-2.8594-4.9492-2.8594-11.051 0-16 2.8555-4.9492 8.1406-8 13.855-8h224c5.7148 0 11 3.0508 13.855 8 2.8594 4.9492 2.8594 11.051 0 16-2.8555 4.9492-8.1406 8-13.855 8z"/>
                <path d="m176 352h-32c-5.7148 0-11-3.0508-13.855-8-2.8594-4.9492-2.8594-11.051 0-16 2.8555-4.9492 8.1406-8 13.855-8h32c5.7148 0 11 3.0508 13.855 8 2.8594 4.9492 2.8594 11.051 0 16-2.8555 4.9492-8.1406 8-13.855 8z"/>
                <path d="m176 416h-32c-5.7148 0-11-3.0508-13.855-8-2.8594-4.9492-2.8594-11.051 0-16 2.8555-4.9492 8.1406-8 13.855-8h32c5.7148 0 11 3.0508 13.855 8 2.8594 4.9492 2.8594 11.051 0 16-2.8555 4.9492-8.1406 8-13.855 8z"/>
                <path d="m368 352h-128c-5.7148 0-11-3.0508-13.855-8-2.8594-4.9492-2.8594-11.051 0-16 2.8555-4.9492 8.1406-8 13.855-8h128c5.7148 0 11 3.0508 13.855 8 2.8594 4.9492 2.8594 11.051 0 16-2.8555 4.9492-8.1406 8-13.855 8z"/>
                <path d="m368 416h-128c-5.7148 0-11-3.0508-13.855-8-2.8594-4.9492-2.8594-11.051 0-16 2.8555-4.9492 8.1406-8 13.855-8h128c5.7148 0 11 3.0508 13.855 8 2.8594 4.9492 2.8594 11.051 0 16-2.8555 4.9492-8.1406 8-13.855 8z"/>
            </g>
        </svg>
    )
};

const Biometrics = ({color}) => {
    return (
        <svg width="512pt" height="512pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="m67.918 397.4c8.5977 23.102-26.512 36.152-35.098 13.074-9.8516-26.531-14.93-54.656-14.93-82.926 0-131.37 106.75-238.11 238.11-238.11 131.38 0 238.11 106.74 238.11 238.11 0 28.375-5.043 56.324-14.93 82.926-8.5859 23.078-43.699 10.027-35.098-13.074 6.1445-16.547 9.9844-33.621 11.574-51.051h-32.363v-37.602h32.336c-5.5391-59.73-37.223-111.8-83.512-144.72l-16.188 28.043-32.559-18.801 16.199-28.059c-25.43-11.66-53.723-18.172-83.566-18.172s-58.137 6.5117-83.574 18.168l16.207 28.066-32.559 18.801-16.199-28.055c-46.293 32.91-77.98 85-83.52 144.73h32.352v37.602h-32.363c1.5898 17.43 5.4297 34.48 11.574 51.051zm164.5-112.21c19.406-9.6992 43.195-6.5898 59.391 8.2539 21.418 19.641 22.883 53.242 3.2461 74.66-19.637 21.418-53.242 22.891-74.664 3.2539-16.207-14.863-21.34-38.273-13.383-58.453l-59.445-54.492c-18.234-16.715 7.1797-44.434 25.41-27.723zm12.453 36.906c-5.7617 6.2891-5.3477 15.777 0.92969 21.539 6.2891 5.7578 15.77 5.3477 21.535-0.94531 5.7578-6.2773 5.3516-15.766-0.9375-21.527-6.2812-5.7617-15.766-5.3477-21.527 0.92969z"/>
            </g>
        </svg>
    )
};

const Lab = ({color}) => {
    return (
        <svg width="512pt" height="512pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M291.9,32.1c-7.4-3.7-17.6-0.4-21.3,6.9c-3.8,7.4-0.5,17.5,6.9,21.2L89,383.3c-8.6,14.8-7.1,32.3-0.6,47.6
                            c6.6,15.3,18.7,29.3,35.5,38.9c16.7,9.5,35,13,51.6,11c16.6-2,32.5-9.5,41.1-24.2l188.5-323.2c7.2,4.2,17.6,1.5,21.8-5.7
                            c4.2-7.2,1.4-17.6-5.9-21.6C378.1,81.4,334.8,56.7,291.9,32.1L291.9,32.1z M304.8,75.9l73,41.8l-74.2,127.4l-73-41.8L304.8,75.9z
                            M214.7,230.6l73,41.8l-98.4,168.6c-1.9,3.2-7.9,7.5-17.4,8.6c-9.5,1.1-21.5-0.9-32.1-7c-10.7-6.1-18.5-15.3-22.3-24.1
                            c-3.8-8.8-3.1-16.2-1.2-19.5L214.7,230.6z"/>
            </g>
        </svg>
    )
};

const Appointments = ({color}) => {
    return (
        <svg width="512pt" height="512pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="m178.69 57.855c-9.7266 0-17.922 8.1914-17.922 17.922v7.6797h-31.23c-37.887 0-69.121 31.23-69.121 69.121v244.73c0 37.887 31.23 69.121 69.121 69.121h252.41c37.887 0 69.121-31.23 69.121-69.121v-244.74c0-37.887-31.23-69.121-69.121-69.121h-31.23v-7.6797c0-9.7266-8.1914-17.922-17.922-17.922-9.7266 0-17.922 8.1914-17.922 17.922v7.6797l-118.27 0.003906v-7.6797c0-9.7305-7.6797-17.922-17.922-17.922zm203.78 373.25h-252.93c-18.434 0-33.281-14.848-33.281-33.281l0.003906-175.62h318.98v175.11c0.50781 18.945-14.34 33.793-32.77 33.793zm-67.074-311.81v7.6797c0 9.7266 8.1914 17.922 17.922 17.922 9.7266 0 17.922-8.1914 17.922-17.922v-7.6797h31.23c18.434 0 33.281 14.848 33.281 33.281v33.793l-319.49-0.003906v-33.793c0-18.434 14.848-33.281 33.281-33.281h31.23v7.6797c0 9.7266 8.1914 17.922 17.922 17.922 9.7266 0 17.922-8.1914 17.922-17.922v-7.6797z"/>
                <path d="m221.18 261.12c-0.51172-1.0234-1.0234-2.0469-1.5352-3.0703-0.51172-1.0234-1.5352-2.0469-2.0469-2.5586-1.0234-1.0234-1.5352-1.5352-2.5586-2.0469-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-2.5586-0.51172-4.6094-0.51172-7.168 0-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-1.0234 1.0234-1.5352 1.5352-2.0469 2.5586-0.51172 1.0234-1.0234 2.0469-1.5352 3.0703-0.51172 1.0234-1.0234 2.0469-1.0234 3.5859 0 1.0234-0.51172 2.5586-0.51172 3.5859 0 4.6094 2.0469 9.2148 5.1211 12.801 1.0234 1.0234 1.5352 1.5352 2.5586 2.0469 1.0234 0.51172 2.0469 1.0234 3.0703 1.5352 1.0234 0.51172 2.0469 1.0234 3.5859 1.0234 1.0234 0 2.5586 0.51172 3.5859 0.51172 1.0234 0 2.5586 0 3.5859-0.51172 1.0234 0 2.5586-0.51172 3.5859-1.0234 1.0234-0.51172 2.0469-1.0234 3.0703-1.5352 1.0234-0.51172 2.0469-1.5352 2.5586-2.0469 3.5859-3.0703 5.1211-8.1914 5.1211-12.801 0-1.0234 0-2.5586-0.51172-3.5859-0.011719-1.5391-0.52344-2.5625-1.0352-3.5859z"/>
                <path d="m243.2 255.49c-3.0703 3.5859-5.1211 8.1914-5.1211 12.801 0 4.6094 2.0469 9.2148 5.1211 12.801 3.5859 3.5859 8.1914 5.1211 12.801 5.1211 1.0234 0 2.5586 0 3.5859-0.51172 1.0234 0 2.5586-0.51172 3.5859-1.0234 1.0234-0.51172 2.0469-1.0234 3.0703-1.5352 1.0234-0.51172 2.0469-1.5352 2.5586-2.0469 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586 0.51172-1.0234 1.0234-2.0469 1.5352-3.0703 0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-4.6094-2.0469-9.2148-5.1211-12.801-6.6523-7.1719-18.941-7.1719-25.598-0.003907z"/>
                <path d="m323.59 261.12c-0.51172-1.0234-1.0234-2.0469-1.5352-3.0703-0.51172-1.0234-1.5352-2.0469-2.0469-2.5586-4.0977-4.0977-10.238-6.1445-16.383-5.1211-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-1.0234 1.0234-1.5352 1.5352-2.0469 2.5586-0.51172 1.0234-1.0234 2.0469-1.5352 3.0703-0.51172 1.0234-1.0234 2.0469-1.0234 3.5859 0 1.0234-0.51172 2.5586-0.51172 3.5859 0 4.6094 2.0469 9.2148 5.1211 12.801 1.0234 1.0234 1.5352 1.5352 2.5586 2.0469s2.0469 1.0234 3.0703 1.5352c1.0234 0.51172 2.0469 1.0234 3.5859 1.0234 1.0234 0 2.5586 0.51172 3.5859 0.51172 4.6094 0 9.2148-2.0469 12.801-5.1211 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586 0.51172-1.0234 1.0234-2.0469 1.5352-3.0703 0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-1.0234 0-2.5586-0.51172-3.5859-0.007813-1.0195-0.51953-2.043-1.0312-3.0664z"/>
                <path d="m368.13 252.93c-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-2.5586-0.51172-4.6094-0.51172-7.168 0-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-3.0703 3.5859-5.1211 8.1914-5.1211 12.801 0 1.0234 0 2.5586 0.51172 3.5859 0 1.0234 0.51172 2.0469 1.0234 3.5859 0.51172 1.5352 1.0234 2.0469 1.5352 3.0703 0.51172 1.0234 1.5352 2.0469 2.0469 2.5586 3.0703 3.5859 7.6797 5.1211 12.801 5.1211 5.1211 0 9.2148-2.0469 12.801-5.1211 3.5859-3.0703 5.1211-8.1914 5.1211-12.801s-2.0469-9.2148-5.1211-12.801c-0.51172-0.51172-1.5352-1.5352-2.5586-2.0469z"/>
                <path d="m163.33 304.13c-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-2.5586-0.51172-4.6094-0.51172-7.168 0-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-3.0703 3.5859-5.1211 8.1914-5.1211 12.801 0 4.6094 2.0469 9.2148 5.1211 12.801 3.5859 3.5859 8.1914 5.1211 12.801 5.1211s9.2148-2.0469 12.801-5.1211c3.5859-3.0703 5.1211-8.1914 5.1211-12.801 0-4.6094-2.0469-9.2148-5.1211-12.801-0.51562-0.51172-1.5391-1.5352-2.5625-2.0469z"/>
                <path d="m221.18 312.32c-0.51172-1.0234-1.0234-2.0469-1.5352-3.0703-0.51172-1.0234-1.5352-2.0469-2.0469-2.5586-1.0234-1.0234-1.5352-1.5352-2.5586-2.0469-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-2.5586-0.51172-4.6094-0.51172-7.168 0-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352s-2.0469 1.5352-2.5586 2.0469c-1.0234 1.0234-1.5352 1.5352-2.0469 2.5586s-1.0234 2.0469-1.5352 3.0703c-0.51172 1.0234-1.0234 2.0469-1.0234 3.5859 0 1.0234-0.51172 2.5586-0.51172 3.5859 0 4.6094 2.0469 9.2148 5.1211 12.801 1.0234 1.0234 1.5352 1.5352 2.5586 2.0469 1.0234 0.51172 2.0469 1.0234 3.0703 1.5352 1.0234 0.51172 2.0469 1.0234 3.5859 1.0234 1.0234 0 2.5586 0.51172 3.5859 0.51172 1.0234 0 2.5586 0 3.5859-0.51172 1.0234 0 2.5586-0.51172 3.5859-1.0234 1.0234-0.51172 2.0469-1.0234 3.0703-1.5352 1.0234-0.51172 2.0469-1.5352 2.5586-2.0469 3.5859-3.0703 5.1211-8.1914 5.1211-12.801 0-1.0234 0-2.5586-0.51172-3.5859-0.011719-1.5391-0.52344-2.5625-1.0352-3.5859z"/>
                <path d="m252.41 301.57c-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-3.0703 3.5859-5.1211 8.1914-5.1211 12.801s2.0469 9.2148 5.1211 12.801c1.0234 1.0234 1.5352 1.5352 2.5586 2.0469 1.0234 0.51172 2.0469 1.0234 3.0703 1.5352s2.0469 1.0234 3.5859 1.0234c1.0234 0 2.5586 0.51172 3.5859 0.51172 4.6094 0 9.2148-2.0469 12.801-5.1211 3.5859-3.0703 5.1211-8.1914 5.1211-12.801s-2.0469-9.2148-5.1211-12.801c-4.0977-4.0898-10.242-5.625-16.387-4.6016z"/>
                <path d="m323.59 312.32c-0.51172-1.0234-1.0234-2.0469-1.5352-3.0703s-1.5352-2.0469-2.0469-2.5586c-4.0977-4.0977-10.238-6.1445-16.383-5.1211-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-1.0234 1.0234-1.5352 1.5352-2.0469 2.5586s-1.0234 2.0469-1.5352 3.0703c-0.51172 1.0234-1.0234 2.0469-1.0234 3.5859 0 1.0234-0.51172 2.5586-0.51172 3.5859 0 4.6094 2.0469 9.2148 5.1211 12.801 1.0234 1.0234 1.5352 1.5352 2.5586 2.0469s2.0469 1.0234 3.0703 1.5352c1.0234 0.51172 2.0469 1.0234 3.5859 1.0234 1.0234 0 2.5586 0.51172 3.5859 0.51172 4.6094 0 9.2148-2.0469 12.801-5.1211 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586 0.51172-1.0234 1.0234-2.0469 1.5352-3.0703 0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-1.0234 0-2.5586-0.51172-3.5859-0.007813-1.0195-0.51953-2.043-1.0312-3.0664z"/>
                <path d="m368.13 304.13c-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-2.5586-0.51172-4.6094-0.51172-7.168 0-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-3.0703 3.5859-5.1211 8.1914-5.1211 12.801 0 1.0234 0 2.5586 0.51172 3.5859 0 1.0234 0.51172 2.0469 1.0234 3.5859 0.51172 1.5352 1.0234 2.0469 1.5352 3.0703 0.51172 1.0234 1.5352 2.0469 2.0469 2.5586 3.0703 3.5859 7.6797 5.1211 12.801 5.1211 5.1211 0 9.2148-2.0469 12.801-5.1211 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586s1.0234-2.0469 1.5352-3.0703c0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-4.6094-2.0469-9.2148-5.1211-12.801-0.50781-0.51172-1.5312-1.5352-2.5547-2.0469z"/>
                <path d="m163.33 355.33c-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-5.6328-1.0234-11.777 1.0234-16.383 5.1211-3.0703 3.5859-5.1211 8.1914-5.1211 12.801 0 1.0234 0 2.5586 0.51172 3.5859 0 1.0234 0.51172 2.0469 1.0234 3.5859 0.51172 1.0234 1.0234 2.0469 1.5352 3.0703 0.51172 1.0234 1.5352 2.0469 2.0469 2.5586 3.5859 3.5859 8.1914 5.1211 12.801 5.1211 4.6094 0 9.2148-2.0469 12.801-5.1211 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586 0.51172-1.0234 1.0234-2.0469 1.5352-3.0703 0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-4.6094-2.0469-9.2148-5.1211-12.801-0.50781-1.0273-1.5312-2.0508-2.5547-2.5625z"/>
                <path d="m214.53 355.33c-1.0234-0.51172-2.0469-1.0234-3.0703-1.5352-1.0234-0.51172-2.0469-0.51172-3.5859-1.0234-2.5586-0.51172-4.6094-0.51172-7.168 0-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352s-2.0469 1.5352-2.5586 2.0469c-3.0703 3.5859-5.1211 8.1914-5.1211 12.801 0 1.0234 0 2.5586 0.51172 3.5859 0 1.0234 0.51172 2.0469 1.0234 3.5859 0.51172 1.0234 1.0234 2.0469 1.5352 3.0703 0.51172 1.0234 1.5352 2.0469 2.0469 2.5586 1.0234 1.0234 1.5352 1.5352 2.5586 2.0469 1.0234 0.51172 2.0469 1.0234 3.0703 1.5352 1.0234 0.51172 2.0469 1.0234 3.5859 1.0234 1.0234 0 2.5586 0.51172 3.5859 0.51172 1.0234 0 2.5586 0 3.5859-0.51172 1.0234 0 2.5586-0.51172 3.5859-1.0234 1.0234-0.51172 2.0469-1.0234 3.0703-1.5352 1.0234-0.51172 2.0469-1.5352 2.5586-2.0469 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586 0.51172-1.0234 1.0234-2.0469 1.5352-3.0703 0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-4.6094-2.0469-9.2148-5.1211-12.801-0.50781-0.51172-1.5312-1.5352-2.5547-2.0469z"/>
                <path d="m243.2 357.89c-3.0703 3.5859-5.1211 8.1914-5.1211 12.801s2.0469 9.2148 5.1211 12.801c3.5859 3.5859 8.1914 5.1211 12.801 5.1211 1.0234 0 2.5586 0 3.5859-0.51172 1.0234 0 2.5586-0.51172 3.5859-1.0234 1.0234-0.51172 2.0469-1.0234 3.0703-1.5352 1.0234-0.51172 2.0469-1.5352 2.5586-2.0469 3.5859-3.0703 5.1211-8.1914 5.1211-12.801s-2.0469-9.2148-5.1211-12.801c-6.6562-7.1719-18.945-7.1719-25.602-0.003906z"/>
                <path d="m323.59 363.52c-0.51172-1.0234-1.0234-2.0469-1.5352-3.0703-0.51172-1.0234-1.5352-2.0469-2.0469-2.5586-4.0977-4.0977-10.238-6.1445-16.383-5.1211-1.0234 0.51172-2.0469 0.51172-3.5859 1.0234-1.5352 0.51172-2.0469 1.0234-3.0703 1.5352-1.0234 0.51172-2.0469 1.5352-2.5586 2.0469-1.0234 1.0234-1.5352 1.5352-2.0469 2.5586-0.51172 1.0234-1.0234 2.0469-1.5352 3.0703-0.51172 1.0234-1.0234 2.0469-1.0234 3.5859 0 1.0234-0.51172 2.5586-0.51172 3.5859 0 1.0234 0 2.5586 0.51172 3.5859 0 1.0234 0.51172 2.0469 1.0234 3.5859 0.51172 1.0234 1.0234 2.0469 1.5352 3.0703 0.51172 1.0234 1.5352 2.0469 2.0469 2.5586 1.0234 1.0234 1.5352 1.5352 2.5586 2.0469 1.0234 0.51172 2.0469 1.0234 3.0703 1.5352 1.0234 0.51172 2.0469 1.0234 3.5859 1.0234 1.0234 0 2.5586 0.51172 3.5859 0.51172 1.0234 0 2.5586 0 3.5859-0.51172 1.0234 0 2.5586-0.51172 3.5859-1.0234 1.0234-0.51172 2.0469-1.0234 3.0703-1.5352 1.0234-0.51172 2.0469-1.5352 2.5586-2.0469 1.0234-1.0234 1.5352-1.5352 2.0469-2.5586 0.51172-1.0234 1.0234-2.0469 1.5352-3.0703 0.51172-1.0234 1.0234-2.0469 1.0234-3.5859 0-1.0234 0.51172-2.5586 0.51172-3.5859 0-1.0234 0-2.5586-0.51172-3.5859-0.003906-1.0234-0.51562-2.0469-1.0273-3.0703z"/>
            </g>
        </svg>
    )
};