import styled from 'styled-components';

import {Small} from '../../core/enums/TextSize'

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    margin-top:24px;
`

export const SectionBadge = styled.div`
    display: flex;      
    cursor: pointer;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;    
    width: ${props => props.textSize === Small ? "calc(33.3% - 10px)" : "calc(50% - 10px)"};
    background-color: var(--color-light-grey);
    box-sizing: border-box;
    color: var(--color-primary);
    box-shadow: 0 0 30px var(--color-shadow); 

    ${
        props => props.textSize === Small ? 
        `@media (max-width: 1024px) {
            width: calc(50% - 10px);
        }
        @media (max-width: 580px) {
            width: calc(100%);
        }` :
        `@media (max-width: 920px) {
            width: calc(100%);
        }`
    }

    > div:first-child {
      padding: 12px;
      width: 100%;
      height: 178px;
      svg g {
        pointer-events: bounding-box;
      }
    }

    > div:first-child {
      > svg { 
        width: 100%;
        height: 100%;
        padding: 16px;
      }
    }

    :hover {
      border-color: var(--color-primary-selected);
      color: var(--color-primary-selected);

      > div:first-child {
        > svg g { 
          fill: var(--color-primary-selected);
        }
      }
    }

    > div:last-child {
      background-color: var(--color-white);
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      position: static;
      width: 100%;
      height: 82px;
      left: 16px;
      top: 140px;
      flex: 0 0 auto;
      order: 1;
      align-self: stretch;
      -webkit-box-flex: 0;
    }

    > div:last-child span {
      line-height: 25px;
      font-weight: 600;
      font-size: 26px;
      margin: auto;
      text-align: center;
    }
`;