import React, { useContext } from 'react';

import Moment from 'moment';

import Page from '../../components/containers/Page';
import Grid from '../../components/containers/Grid';
import Error from '../../components/containers/Error'
import Text from '../../components/text/Text';
import Title from '../../components/text/Title';
import ReportIcon from '../../components/icons/Report';

import ReportViewModel from './Report.VM';

import { HealthSummary, Category, Icon, Status, TitleBar } from './Report.Components'

import { getReportValueText, getCategories, getColor } from '../../utils/report';
import { AppContext } from '../../global/App/AppState';
import Button from '../../components/buttons/Button';


const Report = () => {
  const { appProvider } = useContext(AppContext);

  const {
    viewModel
  } = ReportViewModel();

  return (   
    <Page>
      {viewModel.report.data ? 
      ([
        <TitleBar key={"TitleBar"}>
          <Title>
            <Text text={viewModel.report.nipedNumber}/>
          </Title>
          <Text text={Moment(viewModel.report.created).format('DD-MM-YYYY')}/>
        </TitleBar>,
        <Error key={"PageError"} value={viewModel.pageError} />,
        <Error key={"Error"} value={viewModel.error} />,
        <HealthSummary key={"HealthSummary"} checkCVD={viewModel.checkCVD} value={viewModel.report.data.Value}>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M9.99982 11L9.99982 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <circle cx="10.0087" cy="10.0089" r="9" transform="rotate(0.0509495 10.0087 10.0089)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <circle cx="9.99982" cy="14" r="1" transform="rotate(-180 9.99982 14)"/>
          </svg>
          <Text value={getReportValueText(viewModel.report.data.Value, viewModel.checkCVD)} />
        </HealthSummary>, 
        <Grid key={"Grid"}>
        {
          getCategories(viewModel.report)
            .map((category, index) => (
              <Category
                  key={category.Name}
                  index={index} 
                  textSize={appProvider.textSize}
                  onClick={() => {
                    viewModel.onCategoryClick(category.PageID);
                  }}
              >
                <Icon value={category.Color.Value} index={category.PageID}>
                  <ReportIcon index={category.PageID} color={getColor(category.Color.Value, false)}  />
                </Icon>
                <Text text={category.Name} />
                { !category.Title.Value ? '' :
                    <Status value={category.Color.Value}>
                      <Text text={category.Title.Value} />
                    </Status>
                }
              </Category>
            )
          )
        }
        </Grid>,
        <Button key={"DownloadButton"}
          onClick={() => {
            viewModel.onDownload();
          }}
        >
          <Text value="REPORT_BUTTON_DOWNLOAD" />
        </Button>

      ]) : ''
    }
    </Page> 
  )
}

export default Report;

