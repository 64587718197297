export const AttributeIds = {
   Height: "3CE50B43-BB26-4675-9A9F-2EC91C1587B8",
   Weight: "AD4001BA-BA37-417A-854C-6FF49801C480",
   Waist: "844CC6CD-1979-4242-8A74-D249C86AA860",
   Systolic: "9A19726C-5A82-4AE6-B60B-6FC0D9097555",
   Diastolic: "9E09D46A-5F46-4A92-A012-7355D4C00447",
   Pulse: "AB7C5265-8901-4B80-92CB-4C3FA0041EEA"
}

export function getLabelTitle(attributeId) {
    let mapping = {
        '3CE50B43-BB26-4675-9A9F-2EC91C1587B8' : 'DATASET_FIELDS_HEIGHT',
        'AD4001BA-BA37-417A-854C-6FF49801C480' : 'DATASET_FIELDS_WEIGHT',
        '844CC6CD-1979-4242-8A74-D249C86AA860' : 'DATASET_FIELDS_ABDOMINAL',
        '9A19726C-5A82-4AE6-B60B-6FC0D9097555' : 'DATASET_FIELDS_BLOODPRESSURE_SYSTOLIC',
        '9E09D46A-5F46-4A92-A012-7355D4C00447' : 'DATASET_FIELDS_BLOODPRESSURE_DIASTOLIC',
        'AB7C5265-8901-4B80-92CB-4C3FA0041EEA' : 'DATASET_FIELDS_BLOODPRESSURE_PULSE',
        '1971DC8C-E7AA-423E-976B-30BCC3236F81' : 'DATASET_FIELDS_CREATININE_URINE',
        '221BD70A-962B-49C8-94AE-3F52A53EB683' : 'DATASET_FIELDS_HBA1C',
        '972ADA0C-24CA-4CD1-B4F1-4D60F7027155' : 'DATASET_FIELDS_VITAMINED',
        '116438E7-CF5B-430D-B3DC-4F3090C7534C' : 'DATASET_FIELDS_CREATININE_SERUM',
        'E34E5AB6-7EF9-47EC-BFEE-6C457920050E' : 'DATASET_FIELDS_TOTAALCHOLESTEROL',
        'DEE5EAF3-3FAF-49E6-91DF-7F822A2C2BA3' : 'DATASET_FIELDS_LDLCHOLESTEROL',
        'AE9D5C9A-DAD3-4B01-8F14-A9CCFDC15C93' : 'DATASET_FIELDS_GLUCOSE',
        'D0D2D0FA-5E4C-41F8-A2DF-B3CC1D8FCAA9' : 'DATASET_FIELDS_ALBUMINE',
        '8C42D696-5672-424F-AB75-BD7CEB6D1CB8' : 'DATASET_FIELDS_HDLCHOLESTEROL',
        'AEEA7AE3-D1C2-486A-96AE-DFF5C0EFB3F3' : 'DATASET_FIELDS_TRIGLYCERIDEN',
    };

    return mapping[attributeId.toUpperCase()] ?? "DATASET_FIELDS_UNKNOWN";
}

export function toAttributeList(datasets) {
    if(!datasets) 
        return null;

    let classifications = [].concat(...(datasets.data
        .filter(d => d.classifications)
        .map(d => d.classifications
            .map(c => ({ ...c,
                datasetId: d.datasetId
            })))));

    return [].concat(...(classifications
        .filter(c => c.attributes)
        .map(c => c.attributes
            .map(a => ({ ...a, 
                classificationId: c.id,
                datasetId:c.datasetId
            })))));
}

export function hasDataset(attributes, dataset) {
    return attributes.filter(a => a.datasetId.toUpperCase() === dataset.toUpperCase()).length > 0;
}

export function generateDatasets(attributes, nipedNumber) {
    let data = [];

    let datasets = attributes.reduce((group, attribute) => {
        let { datasetId } = attribute;
        group[datasetId] = group[datasetId] ?? [];
        group[datasetId].push(attribute);
        return group;
      }, {});

    Object.keys(datasets).forEach(datasetId => {
        let dataset = {
            datasetId: datasetId,
            nipedNumber: nipedNumber,
            classifications: []
        }

        datasets[datasetId].forEach(d => {
            let classification = dataset.classifications.filter(c => c.id === d.classificationId)[0];
            if(!classification || (classification && classification.attributes.some(a => a.id === d.id))) {
                classification = {
                    id: d.classificationId,
                    attributes: []
                };
                dataset.classifications.push(classification);
            }

            classification.attributes.push({
                id: d.id,
                value: d.value.toString()
            })
        });

        data.push(dataset);
    });

    return data;
}

export function getAttribute(attributes, attributeId, index = 0) {
    if(!attributes) 
        return null;

    return attributes.filter(a => a.id.toUpperCase() === attributeId.toUpperCase())[index];
}

export function getMetadata(attributeId, metadata) {
    let md = metadata.filter(m => m.attributeId.toUpperCase() === attributeId.toUpperCase());
    if(!md || md.length === 0) {
        console.error(`Metadata not found for [${attributeId}]!`);
        return null;
    }
    return md[0];
}

export function validateInput(attributeId, value, metadata) {
    let md = getMetadata(attributeId, metadata);

    if(!md)
        return false;

    // Check if empty and optional.
    if(!value && md.optional)
        return true;
        
    // Check type.
    let v;
    switch (md.type) {
        case "Integer":
            v = parseInt(value);
            break;
        case "Float": 
            v = parseFloat(value);
            break;
        default:
            return false;
    }    
    if(!v) 
        return false;
    // The 'parse<T>' function are a bit strange, as they convert '40 years' to '40'. Check if the converted value as string length is the same as the 'value' length.
    if(v.toString().length !== value.length)
        return false;
    // Validate Minimum
    if(v < md.minimum)
        return false;
    // Validate Maximum
    if(v > md.maximum)
        return false;

    return true;
}

export function sanitizeInput(attributeId, value, metadata) {
    let md = getMetadata(attributeId, metadata);

    if(!md)
        return value;

    switch (md.type) {
        case "Integer":
            return value.replace(/[^0-9]/g, '');
        case "Float": 
            return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        default:
            console.error(`Invalid metadata 'type' property [${md.type}]!`);
            return value;
    }
}