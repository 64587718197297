import styled from "styled-components";

import { getColor, getIconPadding } from "../../utils/report";

export const Content = styled.div`    
    min-height: 128px;
    box-shadow: 0 0 30px var(--color-shadow);  
    padding: 32px;
`;

export const CategoryHeader = styled.div`
    min-height: 64px;
    background-color: ${props => getColor(props.value, true)};
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
        font-size: 25px;
        font-weight: 600;
        margin: 0px 8px;
        color: ${props => getColor(props.value, false)};
        flex-grow: 1;
    }
`;

export const Icon = styled.div`
    background-color: ${props => getColor(props.value, true)};
    padding: ${props => getIconPadding(props.index)};
    width: 35px;
    height: 35px;
    margin: 3px 3px 3px 36px;
`;

export const Status = styled.div`
    color: ${props => getColor(props.value, true)};
    background-color: ${props => getColor(props.value, false)};
    padding: 5px 8px 4px;
    font-size: 14px;
    margin: 3px 36px 3px 3px;
`;