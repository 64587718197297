import { useState } from "react";

import styled, { keyframes } from "styled-components";

import Text from "../text/Text";

const Accordion = (data) =>  {
    const [open, setOpen] = useState(false);

    return (
        <Container open={open} contentPadding={data.contentPadding}>
            <Title
                key="AccordionTitle"
                open={open}
                onClick={() => setOpen(!open)}
                color={data.color}
                icon={data.icon}
                backgroundColor={data.backgroundColor}
            >
                <Text text={data.title } />
            </Title>
            {open && (
                <Content key="Content" open={open} contentPadding={data.contentPadding}>
                    {data.elements.map((component, i) => {
                        if (component.type === 0)
                            return <Text key={i} text={component.value} />;
                        else return component.value;
                    })}
                </Content>
            )}
        </Container>
    );
}

export default Accordion;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${props => (props.contentPadding ? props.contentPadding : "6px 16px")};
    background: rgb(255, 255, 255);
    align-self: stretch;
    margin: 16px 0px;
    border: 1px solid var(--color-medium-grey);
    :hover {
        border-color: var(--color-light-grey);
    }
`;

const Title = styled.div`
    width: 100%;
    padding: 15px;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.color};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "white")};
    cursor: pointer;
    font-weight: bold;

    ${props => props.icon
        ? `::before {
                content: ' ';   
                background-image: url(${props.icon});
                background-position:center;
                background-size:25px 25px;
                width:25px;
                height:25px;
                border-radius:50%;
                background-color: ${props.color};
                font-size:18px;
                font-family: fontAwesome;
                float:left;
                margin-right:15px;
                color:white;
                text-align:center;
            }`
        : ``
    } 
    
    ::after {
        content: "";
        font-size: 24px;
		font-family: fontAwesome;
        transform: rotate(${props => (props.open ? "180" : "0")}deg);
        margin-top: -5px;
        float: right;
    }
`;

const Content = styled.div`
    animation: ${props => (props.open ? SlideDown : SlideUp)} 1.3s ease;
    overflow: hidden;
    white-space: pre-line;
    padding: ${props => (props.contentPadding ? props.contentPadding : "25px 4px;")};
`;

const SlideDown = keyframes`
    from {
        max-height: 0;
    }
    to {
        max-height:1000px;
    }
`;

const SlideUp = keyframes`
    from {
        max-height: 1000px;
    }
    to {
        max-height:0;
    }
`;