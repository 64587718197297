export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const download = (data, name) => {
    // It is necessary to create a new blob object with mime-type explicitly set otherwise only Chrome works like it should
    let blob = new Blob([data], { type: "application/pdf" });
    // IE doesn't allow using a blob object directly as link href instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
    }
    // For other browsers, create a link pointing to the ObjectURL containing the blob.
    let link = document.createElement("a");
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
};

export function getQueryVariable(name, url, decode = true) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decode === true ? decodeURIComponent(results[2]) : results[2];
}
