import { useContext, useState, useEffect } from 'react';

import { useParams, useNavigate  } from "react-router-dom";

import { ConfigurationContext } from '../../../global/Configuration/ConfigurationState';
import {ModalContext} from '../../../global/Modal/ModalState';
import { WebServiceContext } from '../../../global/WebService/WebServiceState';

import { generateDatasets, getAttribute, sanitizeInput, toAttributeList, validateInput } from '../../../utils/dataset';

const LabViewModel = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { configurationProvider } = useContext(ConfigurationContext);
    const { modalProvider } = useContext(ModalContext);
    const { professionalWebService, error } = useContext(WebServiceContext);
    
    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [inCheckMode, setInCheckMode] = useState(false);

    const [dependancies] = useState({
        isBusy: isBusy,
        id: id,
        professionalWebService: professionalWebService,
        attributes: attributes
    });

    useEffect(() => {
        const fetchData = async function (professionalWebService, id) {
            let atts = toAttributeList(await professionalWebService.getLab(id));
            atts.forEach(a => a.check = "");
            setAttributes(atts);
        }
        if(!dependancies.isBusy) {
            setIsBusy(true);
            fetchData(dependancies.professionalWebService, dependancies.id);
            setIsBusy(false);
        }

        return () => {
        };
        
    }, [dependancies]);

    function validate() {
        attributes.forEach(a => {
            a.isInvalid = !validateInput(a.id, a.value, configurationProvider.metadata);
        });
        setAttributes([...attributes]);
        return !attributes.some(a => a.isInvalid === true);
    }

    function onChange(attributeId, e, index) {
        let attribute = getAttribute(attributes, attributeId, index);
        attribute.value = e.target.value;
        attribute.isInvalid = !validateInput(attributeId, e.target.value, configurationProvider.metadata);
        setAttributes([...attributes]);
    }

    function onChangeCheck(attributeId, e, index) {        
        let attribute = getAttribute(attributes, attributeId, index);
        attribute.check = e.target.value;
        attribute.isInvalid = !validateInput(attributeId, e.target.value, configurationProvider.metadata);
        if(attribute.isInvalid === false) 
            attribute.isInvalid = attribute.value !== attribute.check;
        setAttributes([...attributes]);
    }

    function onInput(attributeId, e) {
        e.target.value = sanitizeInput(attributeId, e.target.value, configurationProvider.metadata);
    }

    async function onSubmit() {
        if(!inCheckMode) return;
        
        if(!validate()) 
            return;

        setIsBusy(true);
        let result = await professionalWebService.putLab(id, generateDatasets(attributes, id));
        setIsBusy(false);
        
        // 204 NoContent is returned.
        if(result === '') 
            modalProvider.showModal("LAB_MODAL_SAVED_TITLE", "LAB_MODAL_SAVED_DESCRIPTION", null, onPrevious, null, null, null, onPrevious); 
        else 
            modalProvider.showModal("LAB_MODAL_ERROR_TITLE", "LAB_MODAL_ERROR_DESCRIPTION", null,  modalProvider.hideModal); 
    }

    function onBack() {
        modalProvider.hideModal();

        if(!inCheckMode)
            navigate(-1);
        else 
            setInCheckMode(false);
    }

    function onPrevious() {
        modalProvider.hideModal();
        navigate(-1);
    }

    return {
        viewModel: {
            nipedNumber: id,
            attributes: attributes,
            inCheckMode: inCheckMode, 
            setInCheckMode: setInCheckMode,
            validate: validate,
            onChange: onChange,
            onChangeCheck: onChangeCheck,
            onInput: onInput,
            onSubmit: onSubmit,
            onBack: onBack,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default LabViewModel;