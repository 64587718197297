import React from 'react';

import Moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/buttons/Button";
import Error from "../../components/containers/Error";
import FlexBox from "../../components/containers/FlexBox";
import Form from "../../components/containers/Form";
import Introduction from "../../components/containers/Introduction";
import MarginBox from "../../components/containers/MarginBox";
import Page from "../../components/containers/Page";
import Text from "../../components/text/Text";
import Title from "../../components/text/Title";
import Grid from '../../components/containers/Grid';

import { CloseButton } from '../../components/modals/Modal';
import { Item, ItemHeader, ItemRow, PopUp, PopUpScreen } from './Checkpoints.Components';

import CheckpointsViewModel from "./Checkpoints.VM";
import { SuccessText } from "../../components/containers/Success";

const Checkpoints = () => {
  const { viewModel } = CheckpointsViewModel();

  return [
    <PopUpScreen key={"PopUpScreen"} visible={viewModel.popUpVisible}>
      <div>
        <PopUp>
          <Title>
            <Text value="CHECKPOINTS_POPUP_FILTER_TITLE" />
          </Title>
          <Text value="CHECKPOINTS_POPUP_FILTER_TEXT" />

          <Button
            onClick={(e) => {
              e.preventDefault();
              viewModel.onFilter(null, viewModel.filterItems[0].type);
            }}
          >
            <Text value="CHECKPOINTS_POPUP_FILTER_BUTTON_ALL" />
          </Button>
          {viewModel.filterItems.map((f) => (
            <Button
              key={f.key}
              onClick={(e) => {
                e.preventDefault();
                viewModel.onFilter(f.key, f.type);
              }}
            >
              <Text text={f.value} />
            </Button>
          ))}

          <MarginBox
            top="16px"
            onClick={(e) => {
              e.preventDefault();
              viewModel.setPopUpVisible(false);
            }}
          >
            <CloseButton>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
          </MarginBox>
        </PopUp>
      </div>
    </PopUpScreen>,
    <Page key={"Page"}>
      <FlexBox gap={"0px"}>
        <Introduction>
          <Title>
            <Text value="CHECKPOINTS_INTRODUCTION_TITLE" />
          </Title>
          <Text value="CHECKPOINTS_INTRODUCTION_TEXT" />
        </Introduction>
        <Form>
          <h3>
            <Text value="CHECKPOINTS_TITLE" />
          </h3>
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <MarginBox bottom={"20px"}>
            <Text value="CHECKPOINTS_TEXT_DOWNLOAD" />
          </MarginBox>
          <Button
            onClick={(e) => {
              e.preventDefault();
              viewModel.onDownload();
            }}
          >
            <Text value="CHECKPOINTS_BUTTON_DOWNLOAD" />
          </Button>
          <MarginBox top={"32px"} bottom={"20px"}>
            <Text value="CHECKPOINTS_TEXT_REFRESH" />
          </MarginBox>
          <Button
            onClick={(e) => {
              e.preventDefault();
              viewModel.onRefresh();
            }}
          >
            <Text value="CHECKPOINTS_BUTTON_REFRESH" />
          </Button>
        </Form>
      </FlexBox>
      <MarginBox top={"32px"} bottom={"20px"}>
        <hr />
      </MarginBox>
      <FlexBox>
        <Button
          onClick={(e) => {
            e.preventDefault();
            viewModel.onShowFilter(1);
          }}
        >
          {viewModel.filter.checkpoint ? <Text text={viewModel.filter.checkpoint.name + " (" + viewModel.filter.checkpoint.department + ")"} /> : <Text value="CHECKPOINTS_BUTTON_FILTER_CHECKPOINT" />}
        </Button>
        {viewModel.filter.checkpoint ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              viewModel.onShowFilter(2);
            }}
          >
            {viewModel.filter.date ? <Text text={Moment(viewModel.filter.date).format("DD-MM-YYYY")} /> : <Text value="CHECKPOINTS_BUTTON_FILTER_DATE" />}
          </Button>
        ) : (
          ""
        )}
        {viewModel.filter.date ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              viewModel.onShowFilter(3);
            }}
          >
            {viewModel.filter.time ? <Text text={Moment("1970-01-01T" + viewModel.filter.time).format("HH:mm")} /> : <Text value="CHECKPOINTS_BUTTON_FILTER_TIME" />}
          </Button>
        ) : (
          ""
        )}
      </FlexBox>
      <MarginBox top={"20px"} bottom={"20px"}>
        <hr />
      </MarginBox>
      <Grid>
        {viewModel.appointments.map((c) => (
          <Item key={c.nipedNumber + c.date}>
            <div
              onClick={() => {
                viewModel.onClick(c);
              }}
            >
              <ItemHeader>
                <h2>{c.nipedNumber}</h2>
                <div>{Moment(c.date).format("DD-MM-YYYY HH:mm")}</div>
                {c.barcodes && c.barcodes.length && <SuccessText value={"SOME_BARCODES_ASSIGNED"} />}
              </ItemHeader>
              <ItemRow>
                <Text text={(c.name && c.name.lastname) || "..."} />
                <Text text={(c.name && c.name.firstname) || "..."} />
                <Text text={c.sex || "..."} />
                {/* <Text text={c.dateOfBirth ? Moment(c.dateOfBirth).format("DD-MM-YYYY HH:mm") : "..."} /> */}
              </ItemRow>
              <ItemRow isEven>
                <Text text={c.nameLocation || "..."} />
                <Text text={c.department || "..."} />
              </ItemRow>
              <ItemRow>
                <Text text={(c.street || "...").concat(" ", c.houseNumber || "...")} />
                <Text text={(c.zipcode || "...").concat(" ", c.city || "...")} />
                <Text text={c.country || "..."} />
              </ItemRow>
            </div>
          </Item>
        ))}
      </Grid>
    </Page>,
  ];
};

export default Checkpoints;

