import styled from 'styled-components';

const FlexBox = styled.div`
    display: flex;
    gap: ${props => props.gap || "20px"};
    > button {
        width: 100%;
    }

    @media (max-width: 820px) {
        flex-direction:column;
        gap: 0px;
        width: 100%;
    }
`;

export default FlexBox;