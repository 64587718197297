import { useState } from 'react';

import axios from 'axios';

import {doApiCall, handleResponse} from '../../../utils/services'

function useIdentityWebService(appProvider, url, setAccessToken, createHeaders, setError) {
    const uri = url;
    const [isBusy, setIsBusy] = useState(false);

    async function postLogin(username, password, tfa) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        const params = new URLSearchParams();
        params.append("username", username);
        params.append("password", password);
        if (tfa) params.append("TFA", tfa);
        params.append("grant_type", "password");

        result = await axios.post(`${uri}/connect/token`, params, {
          headers: {
            Authorization: "Basic UEdDOg==",
          },
          validateStatus: null,
        });
      });

      if (result && result.status === 400 && result.data.error_description && result.data.error_description === "tfa_required") {
        return {
          tfa_required: true,
        };
      }

      if (await handleResponse(result, setError)) {
        setAccessToken(result.data.access_token);
        return result.data;
      }

      return null;
    }

    async function getUsername(rfid) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/accounts/${rfid}`, {
          headers: {
            Authorization: "Basic UEdDOg==",
          },
        });
      });
      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function resetPassword(username, password, token) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.put(
          `${uri}/accounts/password/${token}`,
          {
            username: username,
            password: password,
          },
          {
            headers: createHeaders(),
          }
        );
      });

      if (result && result.status === 200) {
        return result.data;
      }
      if (handleResponse(result, setError)) {
        return result.data.data;
      }
      return null;
    }

    return {
      postLogin,
      resetPassword,
      getUsername,
    };
}

export default useIdentityWebService;