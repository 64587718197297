import styled from "styled-components";

import FlexBox from "./FlexBox";

const InfoRowBox = styled(FlexBox)`
  ${(props) => props.$lineHeight && `line-height: ${props.$lineHeight};`}
  .dob {
    width: 100%;
  }

  justify-content: space-between;
  margin: 5px;
  > span {
    width: 100%;
  }

  > span:first-child {
    text-align: right;
  }

  > span:last-child {
    font-weight: bold;
  }

  @media (max-width: 820px) {
    > span:first-child {
      text-align: left;
    }

    > span:last-child {
      text-align: right;
    }
  }
  .textDob {
    height: 40px;
    line-height: 40px;
  }
`;

export default InfoRowBox;