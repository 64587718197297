import { useContext, useState, useEffect } from 'react';

import { useParams, useNavigate  } from "react-router-dom";

import { ConfigurationContext } from '../../../global/Configuration/ConfigurationState';
import {ModalContext} from '../../../global/Modal/ModalState';
import { WebServiceContext } from '../../../global/WebService/WebServiceState';

import { generateDatasets, getAttribute, sanitizeInput, toAttributeList, validateInput } from '../../../utils/dataset';

const BiometricViewModel = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { configurationProvider } = useContext(ConfigurationContext);
    const { modalProvider } = useContext(ModalContext);
    const { professionalWebService, error } = useContext(WebServiceContext);
    
    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [attributes, setAttributes] = useState([]);

    const [dependancies] = useState({
        isBusy: isBusy,
        id: id,
        professionalWebService: professionalWebService,
        attributes: attributes
    });

    useEffect(() => {
        const fetchData = async function (professionalWebService, id) {
            setAttributes(toAttributeList(await professionalWebService.getBiometric(id)));
        }
        if(!dependancies.isBusy) {
            setIsBusy(true);
            fetchData(dependancies.professionalWebService, dependancies.id);
            setIsBusy(false);
        }

        return () => {
        };
        
    }, [dependancies]);

    function validate() {
        attributes.forEach(a => {
            a.isInvalid = !validateInput(a.id, a.value, configurationProvider.metadata);
        });
        setAttributes([...attributes]);
        return !attributes.some(a => a.isInvalid === true);
    }

    function onChange(attributeId, e, index) {
        let attribute = getAttribute(attributes, attributeId, index);
        attribute.value = e.target.value;
        attribute.isInvalid = !validateInput(attributeId, e.target.value, configurationProvider.metadata);
        setAttributes([...attributes]);
    }

    function onInput(attributeId, e) {
        e.target.value = sanitizeInput(attributeId, e.target.value, configurationProvider.metadata);
    }

    async function onSubmit() {
        if(!validate()) 
            return;

        setIsBusy(true);
        let result = await professionalWebService.putBiometric(id, generateDatasets(attributes, id));
        setIsBusy(false);
        
        // 204 NoContent is returned.
        if(result === '') 
            modalProvider.showModal("BIOMETRIC_MODAL_SAVED_TITLE", "BIOMETRIC_MODAL_SAVED_DESCRIPTION", null, onBack, null, null, null, onBack); 
        else 
            modalProvider.showModal("BIOMETRIC_MODAL_ERROR_TITLE", "BIOMETRIC_MODAL_ERROR_DESCRIPTION", null, modalProvider.hideModal); 
    }

    function onBack() {
        modalProvider.hideModal();
        navigate(-1);
    }

    return {
        viewModel: {
            nipedNumber: id,
            attributes: attributes,
            onChange: onChange,
            onInput: onInput,
            onSubmit: onSubmit,
            onBack: onBack,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default BiometricViewModel;