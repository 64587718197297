import React, {useState} from "react";

export const StorageContext = React.createContext();

export const StorageProvider = ({children}) => {

    const [report, setReport] = useState({});
    const [reports, setReports] = useState([]);
    const [biometrics, setBiometrics] = useState([]);
    const [labs, setLabs] = useState([]);
    const [checkpoints, setCheckpoints] = useState([]);
    const [checkpointsFilter, setCheckpointsFilter] = useState([]);

    const provider = {
        storageProvider: {
            reports: reports,
            setReports: setReports,
            report: report,
            setReport: setReport,
            clearReports: clearReports,
            biometrics: biometrics,
            setBiometrics: setBiometrics,
            clearBiometrics: clearBiometrics,
            labs: labs,
            setLabs: setLabs,
            clearLabs: clearLabs,
            checkpoints: checkpoints,
            setCheckpoints: setCheckpoints,
            clearCheckpoints: clearCheckpoints,
            checkpointsFilter: checkpointsFilter,
            setCheckpointsFilter: setCheckpointsFilter,
            clear: clear, 
        }
    };

    function clear() {
        clearReports();
        clearBiometrics();
        clearLabs();
        clearCheckpoints();
    }

    function clearReports() {
        setReports([]);
        setReport({});
    }

    function clearBiometrics() {
        setBiometrics([]);
    }

    function clearLabs() {
        setLabs([]);
    }

    function clearCheckpoints() {
        setCheckpoints([]);
        setCheckpointsFilter({});
    }

    return (
        <StorageContext.Provider value={provider}>
            {children}
        </StorageContext.Provider>
    )
};