import { useState } from 'react';

import axios from 'axios';

import {doApiCall, handleResponse} from '../../../utils/services'

function useProfessionalWebService(appProvider, url, createHeaders, setError) {
    const uri = url;
  const [isBusy, setIsBusy] = useState("");


    async function getReports(search) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/report/search?filter=${search}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data.data;
      }
      return null;
    }

    async function getReport(identifier) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/report/${identifier}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function getReportAsPDF(identifier) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/report/${identifier}/download`, {
          headers: createHeaders(),
          responseType: "arraybuffer",
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function releaseReport(globalId) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.put(`${uri}/report/${globalId}/release`, null, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function getBiometrics(search) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/biometrics/search?filter=${search}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data.data;
      }
      return null;
    }

    async function getBiometric(identifier) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/biometrics/${identifier}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function putBiometric(identifier, data) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.put(
          `${uri}/biometrics/${identifier}`,
          {
            data: data,
          },
          {
            headers: createHeaders(),
          }
        );
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function getLabs(search) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/lab/search?filter=${search}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data.data;
      }
      return null;
    }

    async function getLab(identifier) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/lab/${identifier}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function putLab(identifier, data) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.put(
          `${uri}/lab/${identifier}`,
          {
            data: data,
          },
          {
            headers: createHeaders(),
          }
        );
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function getCheckpoints() {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/checkpoint`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data.data;
      }
      return null;
    }

    async function getCheckpointsAsExcel(checkpointId, date, time) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/checkpoint/download?checkpointId=${checkpointId}&date=${date}&time=${time}`, {
          headers: createHeaders(),
          responseType: "arraybuffer",
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function getCheckpoint(identifier) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/checkpoint/${identifier}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function postCheckpoint(identifier, appointmentGlobalId, nipedNumber, data) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.post(`${uri}/checkpoint/${identifier}/appointment/${appointmentGlobalId}/${nipedNumber}`, data, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function deleteCheckpoint(identifier, appointmentGlobalId, nipedNumber) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.delete(`${uri}/checkpoint/${identifier}/appointment/${appointmentGlobalId}/${nipedNumber}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }
    async function validateDob(identifier, appointmentGlobalId, nipedNumber, dob) {
      var result;
      await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.get(`${uri}/checkpoint/${identifier}/appointment/${appointmentGlobalId}/${nipedNumber}/validate?dateTime=${dob}`, {
          headers: createHeaders(),
        });
      });

      if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    async function postBarcode(identifier, appointmentGlobalId, nipedNumber, barcode) {
      var result;
      var conflict;
      conflict = await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
        result = await axios.post(
          `${uri}/checkpoint/${identifier}/appointment/${appointmentGlobalId}/${nipedNumber}/barcode`,
          { barcode: barcode },
          {
            headers: createHeaders(),
          }
        );
      });
      if (conflict) {
        return conflict;
      } else if (handleResponse(result, setError)) {
        return result.data;
      }
      return null;
    }

    return {
      getReports,
      getReport,
      getReportAsPDF,
      releaseReport,
      getBiometrics,
      getBiometric,
      putBiometric,
      getLabs,
      getLab,
      putLab,
      getCheckpoints,
      getCheckpointsAsExcel,
      getCheckpoint,
      postCheckpoint,
      deleteCheckpoint,
      validateDob,
      postBarcode,
    };
}

export default useProfessionalWebService;