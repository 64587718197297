import {useContext, useState} from 'react';

import { useNavigate  } from "react-router-dom";

import {ModalContext} from '../../global/Modal/ModalState';
import {WebServiceContext} from '../../global/WebService/WebServiceState';
import {UserContext} from '../../global/User/UserState';
import {StorageContext} from '../../global/Storage/StorageState';

const LoginViewModel = () => {
    const navigate = useNavigate();
    
    const { modalProvider } = useContext(ModalContext);
    const { identityWebService, error } = useContext(WebServiceContext);
    const { userProvider } = useContext(UserContext);
    const { storageProvider } = useContext(StorageContext);

    const [pageError, setPageError] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [tfa, setTfa] = useState("");
    const [hasTfa, setHasTfa] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    
    async function onLogin() {
        if(!isBusy) {
            setIsBusy(true);

            storageProvider.clear();

            let response = await identityWebService.postLogin(username, password, tfa);

            if(response && response.tfa_required) 
                setHasTfa(true);
            else if(userProvider.initRoles(response)) 
                navigate("/home");
            else 
                setPageError("ERROR_NO_ROLES");

            setIsBusy(false);
        }
    }

    async function onInfo(name, description) {
        modalProvider.showModal(name, description);  
    }

    return {
        viewModel: {
            onLogin: onLogin,
            onInfo: onInfo,
            username: username,
            setUsername: setUsername,
            password: password,
            setPassword: setPassword,
            tfa: tfa,
            setTfa: setTfa,
            hasTfa: hasTfa,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default LoginViewModel;