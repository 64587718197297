import {useContext, useState} from 'react';

import { useNavigate  } from "react-router-dom";

import {WebServiceContext} from '../../global/WebService/WebServiceState';
import {UserContext} from '../../global/User/UserState';
import {StorageContext} from '../../global/Storage/StorageState';

import {ResultManager} from '../../core/constants/Roles';

const ReportsViewModel = () => {
    const navigate = useNavigate();

    const { professionalWebService, error } = useContext(WebServiceContext);
    const { userProvider } = useContext(UserContext);
    const { storageProvider } = useContext(StorageContext);
    
    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [search, setSearch] = useState("");
    
    async function onSearch() {
        if(!isBusy) {
            setIsBusy(true);

            storageProvider.clearReports();

            storageProvider.setReports(await professionalWebService.getReports(search));

            setIsBusy(false);
        }
    }

    async function onRelease(item) {
        if(!isBusy) {
            setIsBusy(true);

            let data = await professionalWebService.releaseReport(item.globalId);

            if(data) {
                item.ready = data.ready;
                item.underReview = data.underReview;
                item.notified = data.notified;
            }
            setIsBusy(false);
        }
    }

    async function onClick(item) {
        navigate(`/report/${item.globalId}`);
    }

    function allowRelease() {
        return userProvider.containsRole([ResultManager]);
    }

    return {
        viewModel: {
            onSearch: onSearch,
            onRelease: onRelease,
            onClick: onClick,
            search: search,
            setSearch: setSearch,
            reports: storageProvider.reports,
            allowRelease: allowRelease,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default ReportsViewModel;