const localizationReducer = (state, action) => {
    switch (action.type) {
      case 'setLocalization': {
        return {
          ...state,
          localizations: action.data,
          isLoading: false
        };
      }
      default:
        return state;
    }
  };
  export default localizationReducer;