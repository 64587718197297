import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Text from "../../text/Text";
const StyledRule = styled.span`
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
  text-decoration: ${(props) => props.valid && "line-through"};
`;

const Rule = (props) => {
  const [state, setState] = useState({
    valid: false,
  });

  const lastProps = useRef({ props });
  useEffect(() => {
    if (lastProps.current.props !== props) {
      checkValidity(props);
    }
  }, [props]);

  const checkValidity = (props) => {
    const valid = props.rule(props);

    if (valid === state.valid) {
      return;
    }

    setState({
      valid: valid,
    });

    props.onChange(props.name, valid);
  };
  useEffect(() => {
    checkValidity(props);
  });

  const { text, valid, password } = props;
  return (
    <div>
      {" "}
      <StyledRule valid={valid} notValid={password.length > 0 && !valid}>
        <Text value={text} />
      </StyledRule>
    </div>
  );
};

export default Rule;
