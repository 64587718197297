import { useContext } from "react";

import styled from "styled-components";

import {ModalContext} from '../../global/Modal/ModalState';

const TooltipLink = (data) => {
    const { modalProvider } = useContext(ModalContext);

    return (
        <span onClick={() => {       
            modalProvider.showModal(null, null, data.elements);  
        } }>
            <Link color={data.color} fontSize={data.fontSize}>
                {data.children}
            </Link>
        </span>
    );
}

export default TooltipLink;

const Link = styled.span`
    color: var(--color-button-primary);
    cursor: pointer;
`;