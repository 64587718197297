import React, {useContext} from 'react';

import styled from 'styled-components';
import { ErrorValidation } from "../containers/Error";
import { LocalizationContext } from "../../global/Localization/LocalizationState";

const Input = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  maxLength,
  onInput = null,
  required = true,
  isInvalid = false,
  type = "text",
  pattern = undefined,
  border,
  onBlur,
  onFocus,
  marginLeft,
  background,
  min,
  max,
  height,
  className,
  password,
  readOnly,
  error,
}) => {
  const { localizationProvider } = useContext(LocalizationContext);

  return (
    <div className={className}>
      <Container
        name={name || label}
        type={type}
        maxLength={maxLength}
        pattern={pattern}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
        placeholder={localizationProvider.getLocalization(placeholder)}
        value={value}
        isInvalid={isInvalid}
        onChange={onChange}
        onInput={onInput}
        marginLeft={marginLeft}
        background={background}
        error={error}
        readOnly={readOnly}
      ></Container>
      {error && !password && <ErrorValidation input value={error} />}
    </div>
  );
};

export default Input;

const Container = styled.input`
  color: inherit;
  border-width: 0px;
  height: ${(props) => (props.height ? props.height : "48px")};
  align-items: flex-start;
  background-color: ${(props) => (props.background ? props.background : "var(--color-tertiary)")};
  border: ${(props) => props.border};
  border-radius: var(--corner-radius-small);
  mix-blend-mode: normal;
  margin-bottom: 15px;
  width: 100%;
  padding: 0px 10px;
  font-size: 1em;

  ${(props) => {
    if (props.isInvalid === true) return `background-color: var(--color-invalid);`;
  }}

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-grey);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-grey);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-grey);
  }

  &[type="date"] {
    padding: 0;
    text-align: center;
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../library/icons/datepicker.svg") 0 0 / 18px 18px no-repeat var(--color-white);
  }
`;