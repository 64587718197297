import {useContext, useState} from 'react';

import { useNavigate  } from "react-router-dom";

import {WebServiceContext} from '../../../global/WebService/WebServiceState';
import {StorageContext} from '../../../global/Storage/StorageState';

const LabsViewModel = () => {
    const navigate = useNavigate();

    const { professionalWebService, error } = useContext(WebServiceContext);
    const { storageProvider } = useContext(StorageContext);

    const [pageError, setPageError] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [search, setSearch] = useState("");
    
    async function onSearch() {
        if(!isBusy) {
            setIsBusy(true);
            
            storageProvider.clearLabs();

            storageProvider.setLabs(await professionalWebService.getLabs(search));

            setIsBusy(false);
        }
    }

    async function onClick(item) {
        navigate(`/lab/${item.nipedNumber}`);
    }

    return {
        viewModel: {
            onSearch: onSearch,
            onClick: onClick,
            search: search,
            setSearch: setSearch,
            labs: storageProvider.labs,
            pageError: pageError,
            setPageError: setPageError,
            error: error,            
            isBusy: isBusy
        }
    };
}

export default LabsViewModel;