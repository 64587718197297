import styled from 'styled-components';

const ButtonRow = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 20px;


    @media (min-width: 720px) {
        button:first-child {
            width: 130px;
        }
    }

    @media (max-width: 720px) {
        flex-flow: column;
        gap: 0;
    }
`;

export default ButtonRow;