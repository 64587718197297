import { useEffect, useReducer, useState } from 'react';

import axios from 'axios';

import { delay } from '../../utils/common';

import configurationReducer from './configurationReducer';

function useConfigurationDataManager(appProvider) {

  const [dependancies] = useState({
    appProvider: appProvider
  });

  const [
    {
      isLoading,
      configuration,
      hasErrored,
      error
    },
    dispatch,
  ] = useReducer(configurationReducer, {
    isLoading: true,
    configuration: null,
    hasErrored: false,
    error: null
  });

  useEffect(() => {
    const fetchData = async function (appProvider) {
      try {
        appProvider.addLoader();
        let result = await axios.get('/config.json');
        await delay(1);
        dispatch({ type: 'setConfiguration', data: result.data });
      } catch (e) {
        dispatch({ type: 'errored', error: e });
      }
      finally {
        appProvider.removeLoader();
      }
    };
    fetchData(dependancies.appProvider);

    return () => {
    };
  }, [dependancies]);

  return {
    isLoading,
    configuration,
    hasErrored,
    error
  };
}
export default useConfigurationDataManager;