import React from 'react';

import Button from '../../components/buttons/Button'
import Text from '../../components/text/Text';
import Title from '../../components/text/Title';
import Error from '../../components/containers/Error';
import Page from '../../components/containers/Page';
import FlexBox from '../../components/containers/FlexBox';
import MarginBox from '../../components/containers/MarginBox';
import Input from '../../components/inputs/Input';
import Introduction from '../../components/containers/Introduction';

import LoginViewModel from './Login.VM';

import { LoginForm } from './Login.Components';

const Login = () => {
  const {
    viewModel
  } = LoginViewModel();

  return (
    <Page>
      <FlexBox gap={"0px"}>
        <Introduction>
          <Title>
            <Text value="LOGIN_INTRODUCTION_TITLE" />
          </Title>
          <Text value="LOGIN_INTRODUCTION_TEXT" />
        </Introduction>
        <LoginForm
          onSubmit={async (event) => {
            event.preventDefault();
            viewModel.onLogin();
          }}
        >
          <h3>
            <Text value="LOGIN_TITLE" />
          </h3>
          <MarginBox bottom={"20px"}>
            <Text value="LOGIN_TEXT" />
          </MarginBox>
          <Input
            placeholder="LOGIN_PLACEHOLDER_USERNAME"
            value={viewModel.username}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setUsername(e.target.value);
            }}
          />
          <Input
            placeholder="LOGIN_PLACEHOLDER_PASSWORD"
            type="password"
            value={viewModel.password}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setPassword(e.target.value);
            }}
          />
          {viewModel.hasTfa ? (
            <div>
              <MarginBox top={"20px"} bottom={"20px"}>
                <Text value="LOGIN_TFA_TEXT" />
              </MarginBox>
              <Input
                placeholder="LOGIN_PLACEHOLDER_TFA"
                value={viewModel.tfa}
                onChange={(e) => {
                  if (!viewModel.isBusy) viewModel.setTfa(e.target.value);
                }}
              />
            </div>
          ) : (
            ""
          )}
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <Button>
            <Text value="LOGIN_BUTTON_SUBMIT" />
          </Button>
          <div
            onClick={async (event) => {
              event.preventDefault();
              viewModel.onInfo("", "LOGIN_TO_HELP_DESCRIPTION");
            }}
          >
            <Text value="LOGIN_TO_HELP" />
          </div>
        </LoginForm>
      </FlexBox>
    </Page>
  );
}

export default Login;