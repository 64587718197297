import * as React from 'react';

import Accordion from "../components/report/Accordion";
import BlockControl from '../components/report/BlockControl';
import Motivation from "../components/report/Motivation";
import TooltipLink from "../components/report/TooltipLink";

import { DataTable, AccordionTable } from '../components/report/Tables';

export function getReportValueText(value, checkCVD) {
    const mapping = {
        1: "REPORT_VALUE_TEXT_GREEN",
        2: "REPORT_VALUE_TEXT_ORANGE",
        3: checkCVD ? "REPORT_VALUE_TEXT_ORANGE" : "REPORT_VALUE_TEXT_RED"
    }

    return mapping[value] ?? "REPORT_VALUE_TEXT_GREY";
}

export function getHeaderColor(value, selected, checkCVD) {
    const mapping = {
        1: ("var(--color-report-green" + (selected ? "-selected" : "") + ")"),
        2: ("var(--color-report-orange" + (selected ? "-selected" : "") + ")"),
        3: checkCVD ? ("var(--color-report-orange" + (selected ? "-selected" : "") + ")") : ("var(--color-report-red" + (selected ? "-selected" : "") + ")")
    }

    return mapping[value] ?? "var(--color-report-grey)";
}

export function getColor(value, selected) {
    const mapping = {
        1: ("var(--color-report-green" + (selected ? "-selected" : "") + ")"),
        2: ("var(--color-report-orange" + (selected ? "-selected" : "") + ")"),
        3: ("var(--color-report-orange" + (selected ? "-selected" : "") + ")"),
        4: ("var(--color-report-red" + (selected ? "-selected" : "") + ")")
    }

    return mapping[value] ?? "var(--color-report-grey)";
}

// Get all categories from the report, excludes grey profiles, and orders descending by color.
export function getCategories(report) {
    let categories = [];
    if(!report || !report.data) return categories;
    if(report.data.Gezondheidsfactoren && report.data.Gezondheidsfactoren.Categories) 
        categories = categories.concat(report.data.Gezondheidsfactoren.Categories);
    if(report.data.Ziekteprocessen && report.data.Ziekteprocessen.Categories) 
        categories = categories.concat(report.data.Ziekteprocessen.Categories);

        return categories.filter(c => !isGrey(c.Color.Value)).sort((a, b) => a.Color.Value < b.Color.Value ? 1 : -1);
}

export function getIconPadding(index) {
    const mapping = {        
        65: "4px",
        62: "4px",
        91: "6px",
        262: "4px",
        379: "6px",
    }

    return mapping[index] ?? "0";
}

export function getRow(index, row, page_id, tooltips, tables) {
    switch (row.Type ? row.Type.toUpperCase() : 'N/A') {
        
        case "N/A":
            return (
                row.Text.Value 
                ? 
                    <Accordion
                        key={index}
                        title={row.Title.Value}
                        elements={parseReportText(row.Text.Value, tooltips)}
                        tooltips={tooltips}
                    />
                : 
                    <BlockControl key={index}
                        elements={parseReportText(
                            row.Title.Value && row.Text.Value
                                ? row.Title.Value + "<br /><br />" + row.Text.Value
                                : row.Title.Value || row.Text.Value,
                            tooltips
                        )} 
                        table={
                            !row.Title.Value.toUpperCase().includes("HOE KUNT") &&
                            !row.Title.Value.toUpperCase().includes("HOE IS HET MOGELIJK") &&
                            !row.Title.Value.toUpperCase().includes("HOW TO") &&
                            !row.Title.Value.toUpperCase().includes("AAN DE SLAG")
                                ? null
                                : getPageTableData(page_id, tables)
                        }
                        children={
                            row.Childs ? row.Childs.map((child, i) => getRow(i + 1000, child, page_id, tooltips, tables)) : ""
                        }>
                    </BlockControl>
            );
        case "BLOCK CONTROL": 
            return (
                <BlockControl key={index}
                    page_id={page_id}
                    elements={parseReportText(
                        row.Title.Value && row.Text.Value
                            ? row.Title.Value + "<br /><br />" + row.Text.Value
                            : row.Title.Value || row.Text.Value,
                        tooltips
                    )} 
                    table={
                        !row.Title.Value.toUpperCase().includes("HOE KUNT") &&
                        !row.Title.Value.toUpperCase().includes("HOE IS HET MOGELIJK") &&
                        !row.Title.Value.toUpperCase().includes("HOW TO GET") &&
                        !row.Title.Value.toUpperCase().includes("AAN DE SLAG")
                            ? null
                            : getPageTable(page_id, tables, row)
                    }
                    children={
                        row.Childs ? row.Childs.map((child, i) => getRow(i + 1000, child, page_id, tooltips, tables)) : ""
                    }>
                </BlockControl>
            );
        case "ROW PROFILE DATA":
        case "ROW PROFILE COMBINATION":
            return (
                <Accordion
                    key={index}
                    title={row.Title.Value}
                    elements={parseReportText(row.Text.Value, tooltips)}
                    color={getColor(row.Color.Value)}
                    tooltips={tooltips}
                />
            );
        case "ROW MOTIVATION":
            return (
                <Motivation
                    key={index}
                    title={row.Title.Value}
                    state={row.State.Value}
                />
            );
        default:
            return <h1 key={index}>{row.Type}</h1>;
    }
}

function isGrey(value) {
    return ["5", "0", ""].includes(value);
};

export const getPageTable = (page_id, tables, row) => {
    let table = getPageTableData(page_id, tables);

    if (!table) return null;

    switch (table.PageID) {
        case 207:
            return <AccordionTable key="table" data={table} />;
        case 203:
        case 209:
            return <DataTable key="table" data={table} />;
        default:
            return null;
    }
};

function parseReportText(text, tootips) {
    let objects = [];
    let i = 0;
    let original = text;
    try {
        while (text.indexOf('<a ng-click="vm.ShowPopup') !== -1) {
            if (i++ >= 25) break;
            let start = text.indexOf('<a ng-click="vm.ShowPopup');
            let end = text.indexOf("</a>", start) + 4;

            let link = text.substring(start, end);
            let popupTitle = link
                .substring(link.indexOf("(") + 1, link.indexOf(")"))
                .split(",")[0]
                .replace("'<u>", "")
                .replace("</u>'", "");
            let popupID = link.substring(link.indexOf("(") + 1, link.indexOf(")")).split(",")[1];
            let linkText = link.substring(link.indexOf("><u>") + 4, link.indexOf("</u></a>"));

            objects.push({ type: 0, value: text.substring(0, start) });
            objects.push({
                type: 1,
                value: React.createElement(
                    TooltipLink,
                    {
                        elements: parseTooltipText(tootips.filter(e => e.ID.toString() === popupID)[0].Value),
                        title: popupTitle,
                        key: 1000 + i
                    },
                    linkText
                )              
            });

            text = text.substring(end);
        }
        if (text) objects.push({ type: 0, value: text });

        return objects;
    } catch (error) {
        console.error(error);
        return [{ type: 0, value: original }];
    }
};

const parseTooltipText = (text) => {
    let objects = [];
    let i = 0;
    let original = text;
    try {
        while (text.indexOf('<a href="event:') !== -1) {
            if (i++ >= 25) break;
            let start = text.indexOf('<a href="event:');
            let end = text.indexOf("</a>", start) + 4;

            let link = text.substring(start, end);
            let params = link
                .substring(link.indexOf('"event:') + 7, link.indexOf('"', link.indexOf('"event:') + 7))
                .split(",");
            let linkText = link.substring(link.indexOf("><u>") + 4, link.indexOf("</u></a>"));
            objects.push({
                type: 0,
                value: text.substring(0, start)
            });

            if (params.length !== 3 || params[0].toLowerCase() !== "showlink") {
                objects.push({
                    type: 0,
                    value: text.substring(start, end)
                });
            } else {
                switch (params[1].toLowerCase()) {
                    case "url":
                        objects.push({
                            type: 1,
                            value: React.createElement(
                                "a",
                                {
                                    href: params[2],
                                    rel: "noopener noreferrer",
                                    target: "_blank",
                                    key: i + 1000
                                },
                                linkText
                            )
                        });
                        break;
                    default:
                        objects.push({
                            type: 0,
                            value: text.substring(start, end)
                        });
                        break;
                }
            }

            text = text.substring(end);
        }
        if (text)
            objects.push({
                type: 0,
                value: text
            });
        return objects;
    } catch (error) {
        return [{ type: 0, value: original }];
    }
};

function getPageTableData(page_id, tables) {
    const mapping = {
        106: 209,
        115: 203,
        118: 207
    };

    if (!mapping[page_id]) return null;

    return tables.filter(x => x.PageID === mapping[page_id]).length > 0
            ? tables.filter(x => x.PageID === mapping[page_id])[0]
            : null;

}