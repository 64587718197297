import React from 'react';

import ReportPageViewModel from './ReportPage.VM';

import Page from '../../components/containers/Page';
import Text from '../../components/text/Text';
import ReportIcon from '../../components/icons/Report';

import { getColor, getPageTable, getRow } from '../../utils/report';
import { CategoryHeader, Content, Icon, Status } from './ReportPage.Components';

const ReportPage = () => {
  const {
    viewModel
  } = ReportPageViewModel();

  return (   
    <Page>
        {viewModel.category ? 
            ([
                <CategoryHeader key={"CategoryHeader"} value={viewModel.category.Color.Value}>
                    <Icon value={viewModel.category.Color.Value} index={viewModel.category.PageID}>
                        <ReportIcon index={viewModel.category.PageID} color={getColor(viewModel.category.Color.Value, false)}  />
                    </Icon>
                    <span>
                        <Text text={viewModel.category.Name} />
                    </span>
                    {   !viewModel.category.Title.Value ? '' :
                            <Status value={viewModel.category.Color.Value}>
                                <Text text={viewModel.category.Title.Value} />
                            </Status>
                    }
                </CategoryHeader>,
                <Content key={"Content"}>
                    {
                        (viewModel.category.Profile && viewModel.category.Profile.Rows) 
                            ? viewModel.category.Profile.Rows.map((row, index) => {
                                return getRow(index, row, viewModel.category.Profile.PageID, viewModel.tooltips, viewModel.tables);
                            })
                            : ''
                    }
                    {
                        (viewModel.category.Advice && viewModel.category.Advice.Rows) 
                            ? viewModel.category.Advice.Rows.map((row, index) => {
                                return getRow(index, row, viewModel.category.Advice.PageID, viewModel.tooltips, viewModel.tables);
                            })
                            : ''
                    }
                    {
                        (viewModel.category.Profile) ? getPageTable(viewModel.category.Profile.PageID, viewModel.tables) : ''
                    }
                    {
                        (viewModel.category.Advice) ? getPageTable(viewModel.category.Advice.PageID, viewModel.tables) : ''
                    }
                </Content>
            ]) : ''
        }
    </Page>
  )
}

export default ReportPage;
