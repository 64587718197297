import styled from 'styled-components';

import Form from '../../components/containers/Form';

export const LoginForm = styled(Form)`
  > div:last-of-type {
    padding: 10px 0px;
    cursor: pointer;
    margin-top: 15px;
    width: fit-content;

    :hover {
      opacity: 0.75;
    }
  }
`;