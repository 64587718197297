import styled from 'styled-components';

import LanguageToggleButton from '../buttons/LanguageToggleButton';
import TextSizeButtons from '../buttons/TextSizeButtons';

const Header = () => {

    return(
        <Container>
            <a href="/">
                <img src="/library/logos/logo-niped.svg" alt="Persoonlijke Gezondheidscheck Logo" />
            </a>
            <div>
                <TextSizeButtons />
                <LanguageToggleButton />
            </div>
        </Container>
    )
}

export default Header;

const Container = styled.header`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    z-index: 1000;
    flex-direction: row;
    padding: 20px 16px 4px;

    a img {
        width: 165px;
    }

    div:first-of-type {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
    }
`;