import React from 'react';

import Moment from 'moment';

import Button from '../../../components/buttons/Button'
import Text from '../../../components/text/Text';
import Title from '../../../components/text/Title';
import Error from '../../../components/containers/Error';
import Page from '../../../components/containers/Page';
import Form from '../../../components/containers/Form';
import FlexBox from '../../../components/containers/FlexBox';
import Grid from '../../../components/containers/Grid';
import MarginBox from '../../../components/containers/MarginBox';
import Introduction from '../../../components/containers/Introduction';
import Input from '../../../components/inputs/Input';

import {Item, ItemHeader, ItemRow} from '../Datasets.Components';

import LabsViewModel from './Labs.VM';

const Labs = () => {
  const {
    viewModel
  } = LabsViewModel();

  return (    
    <Page>
      <FlexBox gap={"0px"}>
        <Introduction>
          <Title>
            <Text value="LABS_INTRODUCTION_TITLE" />
          </Title>
          <Text value="LABS_INTRODUCTION_TEXT" />
        </Introduction>
        <Form onSubmit={async (event) => {
            event.preventDefault();
            viewModel.onSearch();
          }}>
          <h3>
            <Text value="LABS_TITLE" />
          </h3>
          <MarginBox bottom={"20px"}>
            <Text value="LABS_TEXT" />
          </MarginBox>
          <Input placeholder="LABS_PLACEHOLDER_SEARCH"
                  value={viewModel.search}
                  onChange={(e) => {
                    if(!viewModel.isBusy)
                      viewModel.setSearch(e.target.value);
                  }}/>
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <Button>
            <Text value="LABS_BUTTON_SUBMIT"/>
          </Button>
        </Form>
      </FlexBox>
      <Grid>
        {
          viewModel.labs
              .map(d => (
                <Item key={d.nipedNumber}>
                  <div onClick={() => {
                      viewModel.onClick(d);
                  }}>
                    <ItemHeader>
                      <Title>{d.nipedNumber}</Title>
                      <div>{Moment(d.dateOfBirth).format('DD-MM-YYYY')} ({d.sex})</div>
                    </ItemHeader>
                    <ItemRow>
                      <Text value="LABS_FIELDS_ZIPCODE" />
                      {d.zipcode || "..."}
                    </ItemRow>
                    <ItemRow isEven>
                      <Text value="LABS_FIELDS_HOUSENUMBER" />
                      {d.housenumber || "..."}
                    </ItemRow>
                    <ItemRow >
                      <Text value="LABS_FIELDS_FINISHED" />
                      { d.finished ? Moment(d.finished).format('DD-MM-YYYY HH:mm') : "..." }
                    </ItemRow>
                  </div>
                </Item>
              ))
        }
      </Grid>
    </Page>
  );
}

export default Labs;