import React, {useContext} from 'react';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

import Text from '../../components/text/Text';

import {LocalizationContext} from '../../global/Localization/LocalizationState';

const Footer = () => {

    const { localizationProvider } = useContext(LocalizationContext);

    return(
        <div>
            <Container small={true}>
                <a href={"mailto:" + localizationProvider.getLocalization("LINK_EMAIL")}>
                    <FontAwesome name='envelope' />
                    <Text value="FOOTER_MAIL_US" />
                </a>
            </Container>
            <Container>
                <div>
                    <footer>
                        <Content>
                            <Main>
                                <Cell>
                                    <Logo src="/library/logos/pgclogo.svg" alt="PGC Logo" />
                                    <br/>
                                    <Text value="FOOTER_PARTOF" />
                                    <Logo src="/library/logos/nipedlogo.svg" alt="&amp;NIPED Logo" small={true} />
                                </Cell>
                                <Cell>
                                    <Text value="FOOTER_NAVIGATE" />
                                    <a href={localizationProvider.getLocalization("LINK_FAQ")}>
                                        <Text value="FOOTER_FAQ" />
                                    </a>
                                    <a href={localizationProvider.getLocalization("LINK_ABOUT")}>
                                        <Text value="FOOTER_ABOUT" />
                                    </a>
                                </Cell>
                                <Cell>
                                    <Text value="FOOTER_DOWNLOAD_APP" />
                                    <a href={localizationProvider.getLocalization("LINK_IOS")}>
                                        <Text value="FOOTER_IOS" />
                                    </a>
                                    <a href={localizationProvider.getLocalization("LINK_ANDROID")}>
                                        <Text value="FOOTER_ANDROID" />
                                    </a>
                                </Cell>
                                <Cell>
                                    <Text value="FOOTER_ABOUT" />
                                    <address>
                                        <a href={localizationProvider.getLocalization("LINK_ADDRESS")}>
                                            <Text value="FOOTER_ADDRESS" />
                                        </a>
                                        <a href={"mailto:" + localizationProvider.getLocalization("LINK_EMAIL")}>
                                            <Text value="FOOTER_EMAIL" />
                                        </a>
                                        <a href={"tel:" + localizationProvider.getLocalization("LINK_PHONENUMBER")}>
                                            <Text value="FOOTER_TELEPHONE" />
                                        </a>
                                        <div>
                                            <a href={localizationProvider.getLocalization("LINK_INSTAGRAM")}>
                                                <SocialsLogo src="/library/logos/instagram.svg" alt='Instagram' />
                                            </a>
                                            <a href={localizationProvider.getLocalization("LINK_FACEBOOK")}>
                                                <SocialsLogo src="/library/logos/facebook.svg" alt='Facebook' />
                                            </a>
                                            <a href={localizationProvider.getLocalization("LINK_LINKEDIN")}>
                                                <SocialsLogo src="/library/logos/linkedin.svg" alt='LinkedIn' />
                                            </a>
                                        </div>
                                    </address>
                                </Cell>
                            </Main>

                            <Line />

                            <CopyRight>
                                <Row>
                                    <Logo src="/library/logos/iso27.png" alt="ISO Logo" />
                                    <Logo src="/library/logos/nen75.png" alt="NEN Logo" />
                                </Row>
                                <Cell isEnd={true}>
                                    <Row>
                                        <a href={localizationProvider.getLocalization("LINK_PRIVACY")}>
                                            <Text value="FOOTER_PRIVACY" />
                                        </a>
                                        <a href={localizationProvider.getLocalization("LINK_DISCLAIMER")}>
                                            <Text value="FOOTER_DISCLAIMER" />
                                        </a>
                                    </Row>
                                    <span><Text fallbackValue={"© " + new Date().getFullYear()} /> <Text value="FOOTER_COPYRIGHT" /></span>
                                </Cell>
                            </CopyRight>
                        </Content>

                    </footer>
                </div>
            </Container>
        </div>
    )
}

export default Footer;

const Container = styled.div`
    ${props => {
        if(props.small) {
            return `
                display: flex;
                background-color: var(--color-white);
                -webkit-box-pack: justify;
                justify-content: space-between;
                position: fixed;
                bottom: 0px;
                right: 0px;
                left: 0px;
                z-index: 1000;
                visibility: collapse;
            
                a {
                    width: 100%;
                    background-color: var(--color-primary);
                    display: block;
                    height: 42px;
                    line-height: 42px;
                    text-align: center;
                    color: var(--color-white);
                    text-decoration: none;
                    font-weight: bold;

                    > span {
                        margin-left: 8px;
                    }
                }

                a:link {
                    color: var(--color-white);
                    text-decoration: none;
                }

                a:visited {
                    color: var(--color-white);
                    text-decoration: none;
                }

                a:hover {
                    color: var(--color-white);
                    text-decoration: none;
                }

                a:active {
                    color: var(--color-white);
                    text-decoration: none;
                }
            
                @media only screen and (max-width: 780px) {
                    visibility: visible;
                }
            `
        }
        else {
            return `
                display: flex;
                flex-direction: column;
                padding: 0px 0px;
                left: 0.07%;
                right: -0.07%;
                bottom: 0%;
            
                footer {
                    color: white;
                    background-color: var(--color-primary);
                    overflow: hidden;
                    border-radius: var(--corner-radius-large) var(--corner-radius-large) 0px 0px;
                    padding: 3% 4% 1%;
                }
            
                @media only screen and (max-width: 780px) {
                    visibility: collapse;
                    display:none;
                }
            `
        }
    }}
`;

const Content = styled.div`    
    position: relative;
    padding: 16px;
    font-size: 15px;

    a {     
        display: inline-block;
        padding: 4px 0px;
        color: white;
        font-size: 1em;
    }

    a span {     
        margin: auto;
    }

    @media (min-width: 1201px) {
        margin: 0px auto;
    }

    @media (min-width: 1201px) {
        margin: 0px auto;
    }

    @media (min-width: 1024px) {
        width: 100%;
        margin: 0px auto;
    }

    @media (min-width: 768px) {
        padding: 5px;
    }
`;

const Main = styled.div`
    margin-bottom: 48px;
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-self: stretch;
`;

const Cell = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: ${props => props.isEnd === true ? "flex-end" : "flex-start"};
    
    > span:first-child {
        opacity: 0.8;
    };
    
    address {    
        color: white;
        margin-bottom: 0px;
        font-style: normal;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    };
`;

const Logo = styled.img`
    margin: 7px 15px 7px 0px;
    width: ${props => props.small === true ? "100px" : "160px"};
`

const SocialsLogo = styled.img`  
    position: static;
    width: 24px;
    height: 24px;
    margin: 8px;
`;

const Line = styled.div`
    position: static;
    width: 100%;
    opacity: 0.5;
    border: solid rgb(243, 242, 239);
    border-width: 1px 0 0 0;
    flex: 0 0 auto;
    order: 0;
    -webkit-box-flex: 0;
    margin: 24px 0px;
`;

const CopyRight = styled.div`
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-self: stretch;
`;

const Row = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    gap: 4px;
`;