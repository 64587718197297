import React, {useContext} from 'react';
import Text from '../../components/text/Text';
import Page from '../../components/containers/Page';

import HomeViewModel from './Home.VM';

import { Grid, SectionBadge } from './Home.Components';

import {AppContext} from '../../global/App/AppState';
import HomeIcon from '../../components/icons/Home';

const Home = () => {
  
  const { appProvider } = useContext(AppContext);
  const {
    viewModel
  } = HomeViewModel();

  return (    
    <Page>
        <Grid>
            {
                viewModel.sections
                    .map(d => (
                        <SectionBadge key={d.route} textSize={appProvider.textSize} onClick={(r) => {
                          viewModel.onNavigate(d.route);
                        }}>
                            <div>
                              <HomeIcon index={d.index} color={"var(--color-button-primary)"}/>
                            </div>
                            <div>
                                <Text value={d.title}></Text>
                            </div>
                        </SectionBadge>
                    )
                )
            } 
        </Grid>
    </Page>
  );
}

export default Home;