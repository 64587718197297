import styled from 'styled-components';

const Subtitle = styled.h3`
    user-select: none;
    font-size: 28px;
    padding: 10px 0px;
    font-weight: 700;
    color: var(--black);
    letter-spacing: 0px;
    line-height: 26px%;
    mix-blend-mode: normal;
    word-wrap: break-word;
`;

export default Subtitle;