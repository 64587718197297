import styled from 'styled-components';

import {Secondary, Tertiary} from '../../core/enums/ButtonType'

const Button = styled.button`
  ${(props) => {
    switch (props.styleType) {
      case Secondary:
        return `background-color: var(--color-button-secondary);
                        border: 1px solid;
                        border-color: var(--color-button-secondary);
                        color: var(--color-white);`;
      case Tertiary:
        return `background-color: var(--color-black);
                        border: 0px;
                        color: var(--color-white);`;
      default:
        return `background-color: var(--color-button-primary);
                        border: 1px solid;
                        border-color: var(--color-button-primary);
                        color: var(--color-white);`;
    }
  }}
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  height: 50px;
  mix-blend-mode: normal;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  margin: 10px 0px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 16px;
  position: static;
  border-radius: var(--corner-radius-small);
  align-self: stretch;
  -webkit-box-flex: 0;
  flex-grow: 0;

  :hover {
    color: var(--color-white);
    ${(props) => {
      switch (props.styleType) {
        case Secondary:
          return `background-color: var(--color-button-secondary-selected);
                            color: var(--color-button-secondary);`;
        case Tertiary:
          return `background-color: var(--color-black-selected);`;
        default:
          return `background-color: var(--color-button-primary-selected);
                            color: var(--color-button-primary);`;
      }
    }}
  }

  span {
    margin: auto;
  }
`;

export default Button;