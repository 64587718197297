import Moment from "moment";

export function toAppointmentList(checkpoints, filter) {
    if(!checkpoints) 
        return [];

    let appointments = [].concat(
      ...checkpoints
        .filter((c) => c.appointments.data)
        .map((c) =>
          c.appointments.data.map((a) => ({
            ...a,
            projectGlobalId: c.projectGlobalId,
            email: c.email,
            phone: c.phone,
            street: c.street,
            houseNumber: c.houseNumber,
            zipcode: c.zipcode,
            city: c.city,
            country: c.country,
            department: c.department,
            nameLocation: c.name,
          }))
        )
    );
    // Apply Filter.
    if(filter && filter.checkpoint) 
        appointments = appointments.filter(a => a.checkpointGlobalId === filter.checkpoint.globalId);
    if(filter && filter.date)
        appointments = appointments.filter(a => a.date.split('T')[0] === filter.date);
    if(filter && filter.time)
        appointments = appointments.filter(a => a.date.split('T')[1] === filter.time);
    return appointments;
}

export function getAppointment(checkpoints, id) {
    if(!checkpoints) 
        return null;
    let data = toAppointmentList(checkpoints).filter(a => a.globalId === id);
    return data.length > 0 ? data[0] : null;
}

export function selectCheckpoints(checkpoints, type = null) {
    if(!checkpoints || checkpoints.length === 0) 
        return [];
    let list = checkpoints.map(c => ({
        key: c.globalId,
        value: c.name + ' (' + c.department + ')',
        type: type
    }));
    return list;
}

export function selectDates(checkpoint, type = null) {
    if(!checkpoint || !checkpoint.appointments || checkpoint.appointments.data.length === 0)
        return [];
    let list = checkpoint.appointments.data
        .map(a => a.date.split('T')[0])
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(a => ({
            key: a,
            value: Moment(a).format('DD-MM-YYYY'),
            type: type
        }));
    return list;
}

export function selectTimes(checkpoint, date, type = null) {
    if(!checkpoint || !checkpoint.appointments || checkpoint.appointments.data.length === 0 || !date)
        return [];
    let list = checkpoint.appointments.data
        .filter(a => a.date.split('T')[0] === date)
        .map(a => a.date.split('T')[1])
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(a => ({
            key: a,
            value: Moment("1970-01-01T" + a).format('HH:mm'),
            type: type
        }));
    return list;
}