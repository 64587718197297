import styled from 'styled-components';

import FlexBox from '../../components/containers/FlexBox';

export const Item = styled.div`
    width: 100%;
    color: var(--color-black);
    cursor: pointer;
    margin-top: 32px;
    
    > div {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 1024px) {
        width: calc(50% - 6px);
        
        > div {
            display: inherit;
        }

        :hover {
            margin-top: 16px;
        }
    }
    @media (max-width: 580px) {
        width: calc(100%);
    }
`;

export const ItemHeader = styled.div`
    display: flex;
    width: 30%;
    background-color: var(--color-primary);
    align-items: center;
    color: var(--color-white);
    flex-direction: column;
    padding: 0px 12px 16px 12px;
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;
export const ItemRow = styled.div`
    display: flex;
    width: calc(70% / 3);
    background-color: ${props => props.isEven ? "var(--color-light-grey)" : "transparant"};
    align-items: center;
    flex-direction: column;
    padding: 16px 12px 16px 12px;
    flex-direction: column;
    text-align: center;

    span {
        height: 50%;
        text-transform: uppercase;
        font-weight:light;
        font-size:12px;                    
    }
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const ButtonBox = styled(FlexBox)`
  margin-top: 12px;
  > button:first-child {
    width: 50%;
  }

  @media (max-width: 820px) {
    > button:first-child {
      width: 100%;
    }
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  background-color: var(--color-light-grey);  
  padding-top: 12px;

  > span:first-child {
    font-weight: bold;
    padding: 0 12px;
  }
`;

export const InputField = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--color-light-grey);  
  padding: 12px 12px 0 12px;
  justify-content: space-between;
  flex-direction: column;

  > input {
    margin-top: 12px;
    background-color: ${props => props.isInvalid === true ? "var(--color-invalid);" : "var(--color-medium-grey);"}
  }
`;