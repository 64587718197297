import {useContext, useState} from 'react';

import { useParams  } from "react-router-dom";

import { getCategories } from '../../utils/report';

import { StorageContext } from '../../global/Storage/StorageState'

const ReportViewModel = () => {
    const { id } = useParams();

    const { storageProvider } = useContext(StorageContext);
    
    const [pageError, setPageError] = useState("");



    return {
        viewModel: {
            category: (getCategories(storageProvider.report).filter(c => { 
                return c.PageID.toString() === id 
            }))[0],
            tooltips: storageProvider.report.data.Tooltips || [],
            tables: storageProvider.report.data.Tables || [],
            pageError: pageError,
            setPageError: setPageError,
        }
    };
};

export default ReportViewModel;