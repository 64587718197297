import styled from 'styled-components';

const Form = styled.form`
    background-color: var(--color-primary);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    mix-blend-mode: normal;
    margin: 12px 0 12px 0;
    padding: 32px;
    width: 50%;

    h3 {
        font-size: 20px;
    }

    @media (max-width: 820px) {
        width: 100%;
    }
`;

export default Form;