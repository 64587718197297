import styled from 'styled-components';

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin-top:24px;
`;

export default Grid;