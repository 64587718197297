import React, {useContext} from 'react';
import styled from 'styled-components';

import {LocalizationContext} from '../../global/Localization/LocalizationState';
import {AppContext} from '../../global/App/AppState';

import {Medium, Large} from '../../core/enums/TextSize'

const Text = ({text, value, fallbackValue, whiteSpace}) => {
  const { localizationProvider } = useContext(LocalizationContext);
  const { appProvider } = useContext(AppContext);

  let content = value ? localizationProvider.getLocalization(value, fallbackValue) : text;

  if(content)
    content = content.replace(/[\n\r]/g,'<br />')

  return (      
    <Container textSize={appProvider.textSize} 
                whiteSpace={whiteSpace}
                dangerouslySetInnerHTML={{ __html: content }}>
    </Container>
  );
}

export default Text;

const Container = styled.span`
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: ${props => {
    switch(props.textSize) {
      case Medium:
      return "1.25em;"
      case Large:
      return "1.5em;"
      default:
      return "1em;"
    }
  }};   
  white-space: ${props => props.whiteSpace || "normal"};
}`