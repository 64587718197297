import { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { UserContext } from "../../global/User/UserState";
import { LocalizationContext } from "../../global/Localization/LocalizationState";
import { getQueryVariable } from "../../utils/common";

const RegisterUserViewModel = () => {
  const { professionalWebService, error, identityWebService } = useContext(WebServiceContext);
  const navigate = useNavigate();
  const { userProvider } = useContext(UserContext);
  const [success] = useState("");
  const [username, setUsername] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [pageError, setPageError] = useState("");
  const [state, setState] = useState({ password: "", passwordCheck: "" });
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [notEqual, onEqual] = useState(false);
  const [valid, onValidationChange] = useState(true);

  const { localizationProvider } = useContext(LocalizationContext);

  const [dependancies] = useState({
    isBusy: isBusy,
    localizationProvider: localizationProvider,
    professionalWebService: professionalWebService,
    getUsername: getUsername,
  });

  const [token, setToken] = useState(false);

  async function getUsername(rfid) {
    if (!isBusy) {
      const response = await identityWebService.getUsername(rfid);

      if (response.email) {
        setUsername(response.email);
      }
      setIsBusy(false);
    }
  }

  useEffect(() => {
    const email = getQueryVariable("email");
    const token = getQueryVariable("token");
    const cc = getQueryVariable("cc");
    if (cc && dependancies.localizationProvider.locale !== cc.toLowerCase()) {
      dependancies.localizationProvider.toggleLocalization();
    }
    if (email) {
      setUsername(email);
    }
    if (token) setToken(token);
  }, [dependancies]);

  async function sendPasswords() {
    if (state.password !== state.passwordCheck) {
      onEqual(true);
      return;
    } else onEqual(false);

    if (!isBusy) {
      const response = await identityWebService.resetPassword(username, `${state.password}`, token);

      if (response && response.dateTime) {
        const login = await identityWebService.postLogin(username, state.password);
        if (userProvider.initRoles(login)) navigate("/home");
      }

      setIsBusy(false);
    }
  }

  return {
    viewModel: {
      newsletter: newsletter,
      privacy: privacy,
      setNewsletter: setNewsletter,
      setPrivacy: setPrivacy,
      privacyError: privacyError,
      setPrivacyError: setPrivacyError,

      usernameError: usernameError,
      setUsernameError: setUsernameError,
      setUsername: setUsername,
      success: success,
      onValidationChange: onValidationChange,
      notEqual: notEqual,
      onEqual: onEqual,
      valid: valid,
      state: state,
      setState: setState,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
      sendPasswords: sendPasswords,
      password: password,
      setPassword: setPassword,
      passwordCheck: passwordCheck,
      setPasswordCheck: setPasswordCheck,
      username: username,
    },
  };
};

export default RegisterUserViewModel;
