import styled from "styled-components";

import Text from "../text/Text";

const Motivation = (data) =>  {
    return (
        <Container>
            <Text text={data.title} />
            <div>
                <Badge index={1} state={data.state}>1</Badge>
                <Badge index={2} state={data.state}>2</Badge>
                <Badge index={3} state={data.state}>3</Badge>
                <Badge index={4} state={data.state}>4</Badge>
                <Badge index={5} state={data.state}>5</Badge>
            </div>
        </Container>
    );
}

export default Motivation;

function getColor(state, index) {
    if(state !== index.toString())
        return "var(--color-secondary)";        

    switch(index) {
        case 1:
        case 2:
            return "var(--color-report-red)";
        case 3:
            return "var(--color-report-orange)";
        case 4:
        case 5:
            return "var(--color-report-green)";
        default: 
            return "var(--color-report-green)";
    }
}

const Badge = styled.div`
    display: flex;     
    align-items: center;  
    justify-content: center;
    background: ${props => getColor(props.state, props.index)};
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0 5px;
    color: var(--color-white);
    font-weight: bold;

    @media (max-width: 820px) {
        display: ${props => (props.state === props.index.toString()) ? 'flex' : 'none'};     
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--color-light-grey);
    padding: 12px 12px 12px 22px;
    margin: 5px 0;
    > span {
        font-weight: bold;

    }

    > div {
        display: flex;
    }
`;
