import styled from 'styled-components';

import Button from '../../components/buttons/Button'

export const ReleaseButton = styled(Button)`
    margin: 0;
    
    :disabled {
        border-color: var(---color-grey);
        background-color: var(--color-light-grey);
        cursor: not-allowed;
        color: var(---color-grey);
    }
`;

export const Item = styled.div`
    width: 100%;
    color: var(--color-black);
    cursor: pointer;
    margin-top: 32px;
    
    > div {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 1024px) {
        width: calc(50% - 6px);
        
        > div {
            display: inherit;
        }

        :hover {
            margin-top: 16px;
        }
    }
    @media (max-width: 580px) {
        width: calc(100%);
    }
`;

export const ItemHeader = styled.div`
    display: flex;
    width: 30%;
    background-color: var(--color-primary);
    align-items: center;
    color: var(--color-white);
    flex-direction: column;
    padding: 0px 12px 16px 12px;
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;
export const ItemRow = styled.div`
    display: flex;
    width: calc(70% / 5);
    background-color: ${props => props.isEven ? "var(--color-light-grey)" : "transparant"};
    align-items: center;
    flex-direction: column;
    padding: 16px 12px 16px 12px;
    flex-direction: column;
    text-align: center;

    span {
        height: 50%;
        text-transform: uppercase;
        font-weight:light;
        font-size:12px;                    
    }
    
    @media (max-width: 1024px) {
        width: 100%;
    }
`;